
import {
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  coloredShadow,
  main,
  whiteColor,
} from "./material-kit-pro-react.jsx";

const mapStyle = theme => ({
    container,
    mlAuto,
    mrAuto,
    description,
    coloredShadow,
    cardTitle,
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    mapSection: {
        height: "100%",
        "& .map": {
            height: "100%",
            width: "100%",
            "& .mapboxgl-ctrl-bottom-left": {
                left: "110px"
            }
        },
        "& .calculation-box": {
            height: "auto",
            width: "150px",
            position: "absolute",
            bottom: "40px",
            left: "110px",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            padding: "15px",
            textAlign: "center",
        },
        "& .controls-outside-right": {
            position: "absolute",
            right: "20px",
            bottom: "33px",
            top: "85px",
        },
        "& .controls-column": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            pointerEvents: "none",
            margin: "-7.5px 0",
            "&>*": {
                pointerEvents: "all",
                margin: "7.5px 0",
            }
        },
        "& #toolbox": {
            position: "absolute",
            background: whiteColor,
            borderRadius: "10px",
            width: "45px",
            boxShadow: "0 0.125rem 0.3125rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.375rem 0 rgb(0 0 0 / 12%)",
            "& h3, li": {
                height: "35px",
                width: "35px",
                margin: "0 auto",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            },
            "& ul": {
                listStyleType: "none",
                margin: "0",
                padding: "0"
            },
            "& h3": {
                fontSize: "11px",
                fontWeight: "500",
                marginBottom: "0",
            },
            "& li": {
                fontSize: "0",
                marginBottom: "6px",
                cursor: "pointer",
                borderRadius: "7px",
                "&:hover": {
                    background: "#f5f5f5",
                },
                "&.disable": {
                    background: "transparent",
                    cursor: "auto"
                }
            }
        },
        "& .marker": {
            display: "block",
            border: "none",
            cursor: "pointer",
            padding: "0",
            backgroundSize: "cover",
            width: "37px",
            height: "50px",
            "&.black": {
                backgroundImage: "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjYwcHQiIGhlaWdodD0iODFwdCIgdmlld0JveD0iMCAwIDYwIDgxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDsiPgogICAgPGcgaWQ9InN1cmZhY2UxIiB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDEsMCkiPgogICAgICAgIDxwYXRoIGQ9Ik0yOC44NDQsMS41QzEyLjkyNiwxLjUgMCwxNC4zMjggMCwzMC4xMTdDMCwzNi43MTUgMi40MTgsNDIuNjEzIDUuOTc3LDQ3LjU3QzI4Ljg0NCw3OS4zNTkgMjQuNTUxLDc5LjM1OSAyOC44NDQsNzkuMzU5QzMzLjEzNyw3OS4zNTkgMjguODQ0LDc5LjM1OSA1MS43MTUsNDcuNTYzQzU1LjI3LDQyLjYxMyA1Ny42ODQsMzYuNjk1IDU3LjY4NCwzMC4xMTdDNTcuNjg4LDE0LjMyOCA0NC43NzcsMS41IDI4Ljg0NCwxLjVaIiBzdHlsZT0iZmlsbDpyZ2IoMCwwLDApO2ZpbGwtcnVsZTpub256ZXJvO3N0cm9rZTp3aGl0ZTtzdHJva2Utd2lkdGg6MnB4OyIvPgogICAgICAgIDxwYXRoIGQ9Ik00OS42NDgsMzAuMjA3QzQ5LjY0OCw0MS41NTUgNDAuMzk1LDUwLjcxNSAyOC45NjEsNTAuNzE1QzE3LjU0Myw1MC43MTUgOC4yNzMsNDEuNTU1IDguMjczLDMwLjIwN0M4LjI3MywxOC44ODcgMTcuNTQzLDkuNzAzIDI4Ljk2MSw5LjcwM0M0MC4zOTUsOS43MDMgNDkuNjQ4LDE4Ljg4NyA0OS42NDgsMzAuMjA3WiIgc3R5bGU9ImZpbGw6cmdiKDExOSwxMTksMTE5KTtmaWxsLXJ1bGU6bm9uemVybzsiLz4KICAgIDwvZz4KPC9zdmc+Cg==)",
            },
            "&::before": {
                content: "''",
                backgroundSize: "cover",
                display: "block",
                width: "20px",
                height: "20px",
                cursor: "pointer",
                position: "absolute",
                left: "9px",
                top: "7px",
            },
            "&.house::before": {
                backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0id2hpdGUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuNzgzMyAyLjA4MjQxQzExLjkwODggMS45NzI1MyAxMi4wOTExIDEuOTcyNTMgMTIuMjE2NiAyLjA4MjQxTDIyIDEwLjg5OTJMMjEuMTcxNiAxMS43MDg4TDEyLjM4OCAzLjgxMTc5QzEyLjE2NDIgMy42MTI1MSAxMS44MzU4IDMuNjEyNTEgMTEuNjEyIDMuODExNzlMMi44MjM2MiAxMS42ODg5TDIgMTAuODc5MkwxMS43ODMzIDIuMDgyNDFaTTIwLjAwNTggMjAuNDU4MUMyMC4wMDU2IDIwLjkwMzQgMjAuMDA1NSAyMS4xOTc0IDIwLjAwNTUgMjEuMjY1MkMyMC4wMDg2IDIxLjI5MDMgMjAuMDEwNSAyMS4zMTU2IDIwLjAxMDggMjEuMzQwOEMyMC4wMTU4IDIxLjY5OTcgMTkuNzQyNyAyMS45OTQ3IDE5LjQwMDkgMjEuOTk5OUgxNy4xNTM5VjE2Ljg0MThDMTcuMTUzNCAxNS44MTIyIDE2LjM1OCAxNC45Nzc5IDE1LjM3NzIgMTQuOTc4NEMxNC4zOTcyIDE0Ljk3ODkgMTMuNjAyOCAxNS44MTI4IDEzLjYwMjQgMTYuODQxOFYyMS45OTk5SDQuNTc1ODhDNC4xODU1IDIxLjk5OTkgMy45OTUxMiAyMS42OTAxIDMuOTk1MTIgMjEuMjgwMlYxMS43MjM4TDExLjg4MzcgNC42MzE0QzExLjk0OTUgNC41NzI1MSAxMi4wNDY0IDQuNTcyNTEgMTIuMTEyMiA0LjYzMTRMMjAuMDA1NSAxMS43MjM4QzIwLjAwOTUgMTEuNzYxNSAyMC4wMDY4IDE4LjE0NzYgMjAuMDA1OCAyMC40NTgxWk02LjMzMjU1IDE5Ljk1NTdIOC4zMjczMUw4LjM0MTU5IDE4LjAxNjRINi4zMzI1NVYxOS45NTU3Wk02LjMzMjU1IDE3LjQ1NjZIOC4zMjczMUw4LjM0MTU5IDE1LjUwMjRINi4zMzI1NVYxNy40NTY2Wk04Ljg4OTEyIDE5Ljk1NTdIMTAuODk4MlYxOC4wMTY0SDguODg5MTJWMTkuOTU1N1pNOC44ODkxMiAxNy40NTY2SDEwLjg5ODJWMTUuNTAyNEg4Ljg4OTEyVjE3LjQ1NjZaTTEwLjM5ODMgMTAuNTE5MkMxMC4zOTgzIDExLjQ0NjcgMTEuMTE0NSAxMi4xOTg2IDExLjk5NzkgMTIuMTk4NkMxMi44ODEzIDEyLjE5ODYgMTMuNTk3NSAxMS40NDY3IDEzLjU5NzUgMTAuNTE5MkMxMy41OTc1IDkuNTkxNzUgMTIuODgxMyA4LjgzOTg1IDExLjk5NzkgOC44Mzk4NUMxMS4xMTQ1IDguODM5ODUgMTAuMzk4MyA5LjU5MTc1IDEwLjM5ODMgMTAuNTE5MloiIGZpbGw9ImluaGVyaXQiLz4KPC9zdmc+Cg==)",
            }
        }
    }
});

export default mapStyle;
