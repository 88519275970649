
import {
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  coloredShadow,
  whiteColor,
  main,
} from "./material-kit-pro-react.jsx";
import customSelectStyle from "./material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitchStyle from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const markupDetail = theme => ({
    ...customSelectStyle,
    ...customCheckboxRadioSwitchStyle,
    container,
    mlAuto,
    mrAuto,
    description,
    coloredShadow,
    cardTitle,
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    popoverContent: {
        display: "flex",
        flexDirection: "column",
        background: whiteColor,
        borderRadius: "10px",
        zIndex: "5",
        height: "auto",
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        boxShadow: ",0 0.25rem 0.3125rem 0 rgb(0 0 0 / 20%), 0 0.4375rem 0.625rem 0 rgb(0 0 0 / 14%), 0 0.125rem 1rem 0 rgb(0 0 0 / 12%)",
        padding: "0",
        width: "375px",
        [theme.breakpoints.down("xs")]: {
            width: "320px"
        },
        "& header": {
            flex: "0 0 auto",
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "flex-start",
            borderRadius: "5px 5px 0 0",
            padding: "20px 50px 0 20px",
            "& .close": {
                position: "absolute",
                top: "0",
                right: "0",
                zIndex: "10",
                "& button, button:hover": {
                    paddingTop: "5px",
                    background: "transparent"
                }
            },
            "& .titles": {
                flex: "2 1 auto",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "flex-end",
                "& h3": {
                    fontSize: "1.6rem",
                    lineHeight: "1.25",
                    letterSpacing: "-0.02em !important",
                    fontWeight: "bold",
                    margin: "0",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "330px",
                }
            }
        },
        "& .markup-timestamps": {
            fontSize: "1rem",
            lineHeight: "1.4285714286",
            fontWeight: "400",
            color: "#555",
            margin: "0px 20px",
        },
        "& .content": {
            overflowY: "visible",
            overflowX: "hidden",
            width: "100%",
            padding: "20px 25px",
            height: "95vh",
            flex: "1 1 auto",
            "& .tip-line": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px 20px",
                margin: "0 -20px",
                borderBottom: "1px solid #ece8e1",
                "& .svg-icon": {
                    maxWidth: "16px",
                    marginRight: "10px",
                },
                "& p": {
                    fontWeight: "700",
                    flex: "1",
                    display: "inline-block",
                    fontSize: "16px",
                    marginBottom: "0",
                    verticalAlign: "middle",
                    "& span": {
                        fontWeight: "400",
                        color: "#555",
                        marginLeft: "10px",
                    }
                }
            },
            "& .hide-on-map": {
                backgroundColor: "#f5f5f5",
                marginBottom: "15px",
                marginTop: "15px",
                borderRadius: "4px",
                paddingLeft: "10px",
            },
            "& .quick-stats": {
                display: "flex",
                flexFlow: "row wrap",
                padding: "20px 0 30px",
                listStyleType: "none",
                marginBottom: "0px",
                "& .stat": {
                    background: "#f5f5f5",
                    borderRadius: "6px",
                    marginBottom: "10px",
                    padding: "10px",
                    marginRight: "10px",
                },
                "& .metadata2": {
                    fontSize: "0.75rem",
                    lineHeight: "1.4545454545",
                    fontWeight: "500",
                },
                "& .x-distance": {
                    display: "inline-block",
                    marginTop: "0",
                    marginBottom: "0",
                    color: "#222",
                    border: "none",
                    fontSize: "1.8rem",
                    lineHeight: "1.25",
                    letterSpacing: "-0.02em !important",
                    fontWeight: "bold",
                    "& .x-distance-unit": {
                        color: "#777",
                        fontWeight: "400",
                        fontSize: "1.4rem",
                    }
                }
            },
            "& .notes": {
                marginTop: "20px",
            },
            "& .photos-card": {
                marginTop: "30px",
            },
            "& .title4": {
                fontSize: "1.2rem",
                lineHeight: "1.3333333333",
                fontWeight: "bold",
                margin: "0 0 0.75em"                
            },
            "& .image-wrapper": {
                "& .image": {
                    height: "170px",
                    display: "block",
                    margin: "auto",
                    width: "100%",
                    objectFit: "cover",
                    "&:first-child": {
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                    },
                    "&:last-child": {
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                    },
                    "&+.image": {
                        marginTop: "10px"
                    }
                }
            }
        },
        "& footer": {
            flex: "0 0 auto",
            "& .card-toolbar": {
                listStyleType: "none",
                display: "flex",
                flexFlow: "row wrap",
                minHeight: "20px",
                padding: "10px 20px 20px",
                marginBottom: "0px",
                "& .toolbar-option": {
                    color: "#222",
                    textAlign: "center",
                    padding: "0 3px",
                    width: "25%",
                    "& button": {
                        border: "3px solid #e2e2e2",
                        "&:hover": {
                            borderColor: "#8b8b8b",
                            backgroundColor: "transparent"
                        }
                    },
                    "& p": {
                        fontWeight: "700",
                        marginTop: "5px",
                        fontSize: "12px",
                        margin: "5px 0 .75em"
                    }
                }
            }
        }
    },
    label: {
        ...customCheckboxRadioSwitchStyle.label,
    },
    uncheckedIcon: {
        ...customCheckboxRadioSwitchStyle.uncheckedIcon,
        borderColor: "#bdbdbd",
    },
    checkedIcon: {
        ...customCheckboxRadioSwitchStyle.checkedIcon,
        borderColor: "#000",
        background: "#000",
        fill: whiteColor
    },
});

export default markupDetail;
