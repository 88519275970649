import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Warning from "../../components/Typography/Warning.jsx";
import { Link as RouterLink } from "react-router-dom";

import signupPageStyle from "../../../assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";

const Signup = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: "",
            passwordConfirm: "",
            phone: "",
            cell: "",
            address1: "",
            address2: "",
            address3: "",
            code: "",
            countryId: "",
            stateId: "",
            cityId: "",
            dob: "",
            loading: false,
            validation: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                passwordConfirm: '',
                code: '',
                countryId: '',
                stateId: '',
                isValid: false
            },
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            showError: false,
            errorMessage: "",
            user: null,
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
            showLogo: false,
            message: "",
        };
    }
    componentDidMount(){
        //this.loadCountries();
    }
    componentDidUpdate(prevProps, prevState){
        
    }
    componentWillUnmount(){
        if (this.cancelToken) {
            this.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        let values = this.state;

        const requestData = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
        };
        const source = axios.CancelToken.source();
        Api.signUp(requestData, source).then(data => {
            this.setState({
                loading: false, 
                requestCode: false,
                user: data.user,
                message: data.message
            });
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    loading: false, 
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    validateForm(){
        let validation = {
            first_name: "success",
            last_name: "success",
            email: "success",
            password: "success",
            passwordConfirm: "success",
            isValid: true
        };

        if(this.state.first_name.length <= 2){
            validation.first_name = "error";
            validation.isValid = false;
        }
        if(this.state.last_name.length <= 2){
            validation.last_name = "error";
            validation.isValid = false;
        }

        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        
        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        this.loadStates(country);
    }
    loadStates(country){
        const { countryId } = this.state;
        if(countryId === country){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingStates: true,
            countryId: country,
            states: [],
            stateId: ""
        });
        Api.getStates(country, source).then(data => {
            that.setState({
                states: data.response,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        this.loadCities(state);
    }
    loadCities(state){
        const { stateId } = this.state;
        if(stateId === state){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { countryId } = this.state;
        that.setState({
            cancelToken: source,
            loadingCities: true,
            stateId: state,
            cities: [],
            cityId: ""
        });
        Api.getCities(countryId, state, source).then(data => {
            that.setState({
                cities: data.response,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, requestCode, showError, requireLowerletter, requireUpperletter, requireNumber, 
            requireSymbol, requireLength, password, passwordConfirm, message } = this.state;
        
        const { authorized } = this.store.getState();
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");

        if(authorized){
            return <Redirect to='/' />
        }


        return (
            <div>
                <div className={classes.backgroundImg}></div>
                <div className={classes.loginForm}>
                    <div className={classes.container}>
                        <GridContainer justifyContent="center">
                        <GridItem xs={12}>
                            <Card className={classes.cardSignup+" basic-signup"}>
                            <h2 className={classes.cardTitle}>Register</h2>
                            {
                                requestCode === false ?
                                    <p>Please identify yourself and create a secure password for your access</p>
                                :
                                <></>
                            }
                            <CardBody className={classes.cardBody}>
                                <GridContainer justifyContent="center">
                                    <GridItem xs={12} sm={12} md={12}>
                                        {
                                            message !== "" ?
                                                <div>
                                                    <GridItem>
                                                        <Warning>
                                                            {message}
                                                        </Warning>     
                                                        <div className={classes.alreadyHaveAccount}>
                                                            <p>
                                                                <RouterLink to="login">Login</RouterLink>
                                                            </p>
                                                        </div>    
                                                    </GridItem>             
                                                </div>
                                            :
                                            <>
                                                {
                                                    showError ?
                                                        this.renderErrorMessages()
                                                    :
                                                    <></>
                                                }
                                                <form className={classes.form}>
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            className: classes.customFormControlClasses
                                                        }}
                                                        success={validation.first_name === "success"}
                                                        error={validation.first_name === "error"}
                                                        id="input-first_name"
                                                        labelText="First Name"
                                                        inputProps={{
                                                            onChange: (e) => this.handleChange(e, 'first_name'),
                                                            name: "first_name",
                                                            value: this.state.first_name,
                                                        }}
                                                    />
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            className: classes.customFormControlClasses
                                                        }}
                                                        success={validation.last_name === "success"}
                                                        error={validation.last_name === "error"}
                                                        id="input-last_name"
                                                        labelText="Last Name"
                                                        inputProps={{
                                                            onChange: (e) => this.handleChange(e, 'last_name'),
                                                            name: "last_name",
                                                            value: this.state.last_name,
                                                        }}
                                                    />
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            className: classes.customFormControlClasses
                                                        }}
                                                        success={validation.email === "success"}
                                                        error={validation.email === "error"}
                                                        id="input-email"
                                                        labelText="Email"
                                                        inputProps={{
                                                            onChange: (e) => this.handleChange(e, 'email'),
                                                            name: "email",
                                                            value: this.state.email,
                                                            type: "email"
                                                        }}
                                                    />
                                                    {/* <p className={classes.verificationNote}>A verification code will be sent to this email to complete registration.</p> */}
                                                    <CustomInput
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            className: classes.customFormControlClasses
                                                        }}
                                                        success={validation.password === "success"}
                                                        error={validation.password === "error"}
                                                        id="input-password"   
                                                        labelText="Password"                                     
                                                        inputProps={{
                                                            onChange: (e) => this.handleChange(e,'password'),
                                                            name: "password",
                                                            type: "password",
                                                            value: password,
                                                        }}
                                                    />
                                                    {
                                                        showPasswordErrors ?
                                                            <GridItem>
                                                                <div>
                                                                    <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                                    </div>
                                                                    <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                                    </div>
                                                                    <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                                    </div>
                                                                    <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                    </div>
                                                                    <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                    </div>
                                                                </div>
                                                            </GridItem>
                                                        :
                                                        <></>
                                                    }
                                                    <CustomInput
                                                        success={validation.passwordConfirm === "success"}
                                                        error={validation.passwordConfirm === "error"}
                                                        id="input-signupPasswordConfirm"
                                                        labelText="Type Password Again"
                                                        inputProps={{
                                                            required: true,
                                                            onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                                            name: "passwordConfirm",
                                                            type: "password",
                                                            value: passwordConfirm,
                                                        }}                                    
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            className: classes.customFormControlClasses
                                                        }}
                                                    />
                                                    <div className={classes.textCenter}>
                                                        {
                                                            loading ?
                                                                <LoaderComponent />
                                                            :
                                                                <Button round color="primary" onClick={this.handleSubmit}>Signup</Button>
                                                        }
                                                    </div>
                                                    <div className={classes.alreadyHaveAccount}>
                                                        <p>
                                                            Already Have Account? <RouterLink to="login">Login</RouterLink>
                                                        </p>
                                                    </div>
                                                </form>
                                            </>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            </Card>
                        </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(signupPageStyle)(Signup);