import Cookie from './app/assets/js/utils/Cookie';

let configObj = {
    FB_APP_ID: "550186362162847",
    GOOGLE_CLIENT_ID: "368231056525-n3nlvvpjbo257u5tm2ahhkg159fqcsre.apps.googleusercontent.com",
    X_API_KEY: "XIHscYkVIZ8k0NDODleMR9a0wKcuR0Dn2xFwvqlb",
    GOOGLE_ANALYTICS_ID: "UA-85612507-5",
    REACT_APP_LOG_LEVEL: "DEBUG",
    REACT_APP_CLOUDFRONT_IMAGES: "",
    API_URL: "https://uhunt.plazauk.com/public/api/",
    REACT_APP_OAUTH_CONSUMER_KEY: "k8h2kzgr4snfj7bhuv5242ct1k305nlg",
    REACT_APP_OAUTH_CONSUMER_SECRET: "b63cocgu8m73wtskd3lvv5m9esn3b4ju",
    REACT_APP_SESSION_DOMAIN:".plazauk.com",
    REACT_APP_SESSION_SECURE_COOKIE:true,
    BOOKING_APP_DOMAIN:"https://uhunt.plazauk.com",
    getApiUrl(){
        try{
            if(process.env.REACT_APP_API_URL){
                return process.env.REACT_APP_API_URL;
            }
        }catch(e){  }
        return this.API_URL;
    },
    getRedirectUri(){
        let returnUrl = "";
        return "https://"+document.location.host+returnUrl;
    },
    
    getConsumerKey(){
        try{
            if(process.env.REACT_APP_OAUTH_CONSUMER_KEY){
                return process.env.REACT_APP_OAUTH_CONSUMER_KEY;
            }
        }catch(e){  }
        return this.REACT_APP_OAUTH_CONSUMER_KEY;
    },
    getConsumerSecret(){
        try{
            if(process.env.REACT_APP_OAUTH_CONSUMER_SECRET){
                return process.env.REACT_APP_OAUTH_CONSUMER_SECRET;
            }
        }catch(e){  }
        return this.REACT_APP_OAUTH_CONSUMER_SECRET;
    },
    getGoogleAnalyticsId(){
        try{
            if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID){
                return process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
            }
        }catch(e){  }
        return this.GOOGLE_ANALYTICS_ID;
    },
    getFbAppId(){
        try{
            if(process.env.REACT_APP_FB_APP_ID){
                return process.env.REACT_APP_FB_APP_ID;
            }
        }catch(e){  }
        return this.FB_APP_ID;
    },
    getGoogleClientId(){
        try{
            if(process.env.REACT_APP_GOOGLE_CLIENT_ID){
                return process.env.REACT_APP_GOOGLE_CLIENT_ID;
            }
        }catch(e){  }
        return this.GOOGLE_CLIENT_ID;
    },
    getLogType(){
        try{
            if(process.env.REACT_APP_LOG_LEVEL){
                return process.env.REACT_APP_LOG_LEVEL;
            }
        }catch(e){  }
        return this.REACT_APP_LOG_LEVEL;
    },
    getApiHeaders(){
        let authorization = Cookie.read("authorization");
        if(!authorization || authorization === null){
            authorization = this.getConsumerKey();
        }
        const token = Cookie.read("access_token");
        // const secret = Cookie.read("oauth_secret");
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Access-Control-Allow-Origin': document.location.origin,
            // 'oauth-consumer-key': authorization,
        };
        // if(token){
        //     headers['oauth-token'] = token;
        // }
        // if(secret){
        //     headers['oauth-secret'] = secret;
        // }
        if(token){
            headers['Authorization'] = 'Bearer '+token;
        }
        
        return headers;
    },
    getSessionDomain(){
        try{
            if(process.env.REACT_APP_SESSION_DOMAIN){
                return process.env.REACT_APP_SESSION_DOMAIN;
            }
        }catch(e){  }
        return this.REACT_APP_SESSION_DOMAIN;
    },
    getSessionSecure(){
        try{
            if(process.env.REACT_APP_SESSION_SECURE_COOKIE){
                return process.env.REACT_APP_SESSION_SECURE_COOKIE;
            }
        }catch(e){  }
        return this.REACT_APP_SESSION_SECURE_COOKIE;
    },
    getBookingDomian(){
        try{
            if(process.env.BOOKING_APP_DOMAIN){
                return process.env.BOOKING_APP_DOMAIN;
            }
        }catch(e){  }
        return this.BOOKING_APP_DOMAIN;
    }
};

export default configObj;