import React from "react";
import axios from "axios";
import Api from "../../assets/js/utils/Api";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Sidebar from "../components/Sidebar.js";
import Map from "../components/Map.js";
import Popovers from "../components/Popovers/Popovers.js";
import AuthApi from '../../assets/js/utils/Auth';

import homeStyle from "../../assets/jss/homeStyle.jsx";

const Home = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.options = { hour12: false };

        let markupId = null;
        const { match } = this.props.match;
        if(match && match.params.hasOwnProperty("id")){
            markupId = parseInt(match.params.id);
        }
        
        this.state = {            
            popupOpen: false,
            anchorEl: null,
            section: '',
            openSection: '',
            lineFormData: {
                title: 'Line '+new Date().toLocaleString("en-US", this.options),
                notes: '',
            },
            shapeFormData: {
                title: 'Area '+new Date().toLocaleString("en-US", this.options),
                notes: '',
            },
            waypointFormData: {
                title: 'Waypoint '+new Date().toLocaleString("en-US", this.options),
                notes: '',
                attachments: [],
                photoModal: false,
            },
            lineDesign: {
                color: 'rgba(255, 51, 0, 1.0)',
                style: 'solid',
                weight: 'four',
            },
            shapeDesign: {
                color: 'rgba(255, 51, 0, 1.0)',
                fillColor: 'rgba(74, 144, 226, 1.0)',
                style: 'solid',
                weight: 'four',
            },
            waypointDesign: {
                color: 'rgba(255, 51, 0, 1.0)',
                colorName: 'orange',
                icon: 'location'
            },
            formSubmitStatus: false,
            formButtonStatus: false,
            toolsPopoverClosed: false,
            markupsList: [],
            markupsCount: {
                lines: 0,
                shapes: 0,
                waypoints: 0,
                tracks: 0,
            },
            savingInfo: false,
            editMarkupItem: null,
            deleteMarkupItem: null,
            showHideMarkupItem: null,
            loadMarkupItem: null,
            popupMarkupItem: null,
            closePopover: false,
            markupId: markupId
        };
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !AuthApi.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        this.getMarkups('all');
    } 
    getMarkups(type){
        this.setState({
            savingInfo: true,
            showError: false
        });

        const source = axios.CancelToken.source();
        Api.getMarkups(type, source).then((data) => {
            let stateObj = {};
            stateObj['savingInfo'] = false;
            stateObj['markupsList'] = data;

            this.setState(stateObj, () => {                              
                this.countContentTypes(data);
            });
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    countContentTypes(markupsList){
        let linesCount = 0;
        let shapesCount = 0;
        let waypointsCount = 0;
        let tracksCounts = 0;
        markupsList.map(markup => {
            if(markup.isOwner === false){
                return null
            }
            if(markup.type === 'line'){
                linesCount = linesCount+1;
            }else if(markup.type === 'shape'){
                shapesCount = shapesCount+1;
            }else if(markup.type === 'waypoint'){
                waypointsCount = waypointsCount+1;
            }else if(markup.type === 'track'){
                tracksCounts = tracksCounts+1;
            }
            return null;
        });

        let markupsCount = {
            ...this.state.design,
            lines: linesCount,
            shapes: shapesCount,
            waypoints: waypointsCount,
            tracks: tracksCounts
        };

        this.setState({
            markupsCount: markupsCount
        });
    }
    getDateTime(){
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${month<10?`0${month}`:`${month}`}${date}`
    }
    updateSection(section){
        this.setState({
            section: section,
            waypointFormData: {
                ...this.state.waypointFormData,
                photoModal: false
            }
        })

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }
    handleClick = (event, section, popupMarkupItem = null) => {
        if(event){
            event.preventDefault();
        }
        
        if(this.state.section === 'line' || this.state.section === 'area' || this.state.section === 'waypoint' || this.state.section === 'photo'){
            // this condition for if tools popup open and we click on any menu from left sidebar
            this.setState({
                toolsPopoverClosed: true,
                openSection: section,
            })
            return;
        }

        let state = {};
        state['popupOpen'] = true;
        state['toolsPopoverClosed'] =  false;
        state['anchorEl'] =  event !== null ? event.currentTarget : null;
        state['section'] =  section;
        state['openSection'] =  '';
        if(popupMarkupItem !== null){
            state['popupMarkupItem'] = popupMarkupItem;
        }

        this.setState(state);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }
    handleClose = (tools = false) => {
        let state = {};
        if(tools === true){
            state['toolsPopoverClosed'] = true;
            state['openSection'] = '';
            state['popupMarkupItem'] = null;
        }else{
            state['toolsPopoverClosed'] = false;
            state['popupOpen'] = false;
            state['anchorEl'] = null;
            state['formSubmitStatus'] = false;
            state['formButtonStatus'] = false;
            state['section'] = '';
            state['popupMarkupItem'] = null;
            state['closePopover'] = false;
            state['lineFormData'] = {
                title: 'Line '+new Date().toLocaleString("en-US", this.options),
                notes: '',
            };
            state['shapeFormData'] = {
                title: 'Area '+new Date().toLocaleString("en-US", this.options),
                notes: '',
            };
            state['waypointFormData'] = {
                title: 'Waypoint '+new Date().toLocaleString("en-US", this.options),
                notes: '',
                attachments: [],
                photoModal: false
            };
        }
        this.setState(state, () => {
            if(this.state.openSection !== ''){
                this.handleClick(null, this.state.openSection);
            }
        });

        if(this.state.markupId !== null && this.state.openSection === ''){
            this.history.push("/home");
        }
    }
    changeToolsPopoverStatus(){
        this.setState({
            toolsPopoverClosed: false
        })
    }
    updateDesign(design, type){
        let state = {};
        if(type === 'line'){
            state['lineDesign'] = design;
        }else if(type === 'shape'){
            state['shapeDesign'] = design;
        }else if(type === 'waypoint'){
            state['waypointDesign'] = design;
        }

        this.setState(state);
    }
    updateFormData(formData, type){
        let state = {};
        if(type === 'line'){
            state['lineFormData'] = formData;
        }else if(type === 'shape'){
            state['shapeFormData'] = formData;
        }else if(type === 'waypoint'){
            state['waypointFormData'] = formData;
        }

        this.setState(state);
    }
    formSubmit(type){
        this.setState({
            formSubmitStatus: true
        });
    }
    setFormButtonStatus(status){
        this.setState({
            formButtonStatus: status
        })
    }
    setEditMarkupItem(markupItem){
        this.setState({
            editMarkupItem: markupItem
        })
    }
    setDeleteMarkupItem(markupItem, closePopover = false){
        let state = {};
        state['deleteMarkupItem'] = markupItem;
        if(closePopover === true){
            state['closePopover'] = true;
        }
        this.setState(state);
    }
    setShowHideMarkupItem(markupItem){
        this.setState({
            showHideMarkupItem: markupItem
        })
    }
    setLoadMarkupItem(markupItem){
        this.setState({
            loadMarkupItem: markupItem
        })
    }
    removeMarkupContent(markupItem){
        this.setState({
            markupsList: this.state.markupsList.filter(item => item.id !== markupItem.id)
        }, () => {                              
            this.countContentTypes(this.state.markupsList);
            if(this.state.closePopover === true){
                this.handleClose();
            }
        })
    }
    markupsDataUpdate(markup){
        this.setState({
            markupsList: [
                ...this.state.markupsList,
                markup,
            ],
        }, () => {                              
            this.countContentTypes(this.state.markupsList);
        })
    }
    updateMarkupContent(markup){
        var markupIndex = this.state.markupsList.findIndex(function(item) { 
            return item.id === markup.id; 
        });

        const newMarkupItems = [...this.state.markupsList];
        newMarkupItems[markupIndex] = markup;

        this.setState({ 
            markupsList: newMarkupItems 
        });
    }
    render() {
        const { classes } = this.props;
        const { popupOpen, anchorEl, section, lineDesign, shapeDesign, waypointDesign, formSubmitStatus, formButtonStatus, lineFormData, 
            shapeFormData, waypointFormData, toolsPopoverClosed, markupsList, markupId, markupsCount, deleteMarkupItem, showHideMarkupItem,
            loadMarkupItem, popupMarkupItem } = this.state;

        const { authorized } = this.store.getState();
        
        const id = popupOpen ? 'simple-popover' : undefined;

        if(!authorized){
            return (
                <></>
            )
        };
        return (
            <>
                <div className={classes.contentSection}>
                    <div className="map-section">
                        <Sidebar
                            id={id}
                            handleClick={(e, section) => this.handleClick(e, section)}
                            store={this.store}
                        />
                        <Map
                            id={id}
                            popupOpen={popupOpen}
                            toolsPopoverClosed={toolsPopoverClosed}
                            handleClick={(e, section, popupMarkupItem) => this.handleClick(e, section, popupMarkupItem)}
                            lineDesign={lineDesign}
                            shapeDesign={shapeDesign}
                            waypointDesign={waypointDesign}
                            lineFormData={lineFormData}
                            shapeFormData={shapeFormData}
                            waypointFormData={waypointFormData}
                            formSubmitStatus={formSubmitStatus}
                            formButtonStatus={formButtonStatus}
                            setFormButtonStatus={(status) => this.setFormButtonStatus(status)}
                            onClose={this.handleClose}
                            markupsList={markupsList}
                            markupId={markupId}
                            markupsDataUpdate={(markup) => this.markupsDataUpdate(markup)}
                            deleteMarkupItem={deleteMarkupItem}
                            setDeleteMarkupItem={(markupItem) => this.setDeleteMarkupItem(markupItem)}
                            removeMarkupContent={(markupItem) => this.removeMarkupContent(markupItem)}
                            showHideMarkupItem={showHideMarkupItem}
                            setShowHideMarkupItem={(markupItem) => this.setShowHideMarkupItem(markupItem)}
                            updateMarkupContent={(markupItem) => this.updateMarkupContent(markupItem)}
                            loadMarkupItem={loadMarkupItem}
                            setLoadMarkupItem={(markupItem) => this.setLoadMarkupItem(markupItem)}
                            changeToolsPopoverStatus={() => this.changeToolsPopoverStatus()}
                            photoModal={waypointFormData.photoModal}
                            updateSection={(section) => this.updateSection(section)}
                            section={section}
                            store={this.store}
                        />
                    </div>
                </div>
                <Popovers 
                    id={id}
                    popupOpen={popupOpen}
                    anchorEl={anchorEl}
                    onClose={(markup) => this.handleClose(markup)}
                    section={section}
                    lineDesign={lineDesign}
                    shapeDesign={shapeDesign}
                    waypointDesign={waypointDesign}
                    updateDesign={(design, type) => this.updateDesign(design, type)}
                    lineFormData={lineFormData}
                    shapeFormData={shapeFormData}
                    waypointFormData={waypointFormData}
                    updateFormData={(formData, type) => this.updateFormData(formData, type)}
                    formSubmit={(type) => this.formSubmit(type)}
                    formButtonStatus={formButtonStatus}
                    markupsList={markupsList}
                    markupsCount={markupsCount}
                    setEditMarkupItem={(markupItem) => this.setEditMarkupItem(markupItem)}
                    setDeleteMarkupItem={(markupItem, closePopover) => this.setDeleteMarkupItem(markupItem, closePopover)}
                    setShowHideMarkupItem={(markupItem) => this.setShowHideMarkupItem(markupItem)}
                    setLoadMarkupItem={(markupItem) => this.setLoadMarkupItem(markupItem)}
                    popupMarkupItem={popupMarkupItem}
                    store={this.store}
                    history={this.history}
                />
            </>
        );
    }
};

Home.propTypes = {
  classes: PropTypes.object
};
export default withStyles(homeStyle)(Home);
