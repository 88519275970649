
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../CustomButtons/Button.jsx";
import NewFolderModal from "../../Modals/NewFolderModal.js";
import contentStyle from "../../../../assets/jss/contentStyle.jsx";
import { Link } from "react-router-dom";

class MyContent extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;

        this.state = {            
            newFolderModal: false
        };
    }
    onNewFolderModal(status = false){
        this.setState({
            newFolderModal: status,
        });
    }
    getMarkupsCount(markupsList){        
        let markupsCount = 0;
        markupsList.map((markup, key) => {
            if(markup.isOwner === false){
                return null;
            }
            markupsCount = markupsCount+1;

            return null;
        });

        return markupsCount;
    }
    render() {
        const { classes, markupsList, markupsCount } = this.props;
        const { newFolderModal } =  this.state;
        
        return (
            <div className={classes.popoverContent}>
                <header>
                    <div className="close">
                        <IconButton title="Close card" onClick={this.props.onClose}>
                            <span className="x-icon">
                                <svg width="25" height="30" viewBox="0 0 30 30" fill="#333" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.7677 7.62548C21.3772 7.23496 20.744 7.23496 20.3535 7.62548L14.9997 12.9792L9.64623 7.62577C9.25571 7.23524 8.62254 7.23524 8.23202 7.62577L7.62593 8.23186C7.23541 8.62238 7.23541 9.25555 7.62593 9.64607L12.9794 14.9996L7.62556 20.3534C7.23503 20.7439 7.23503 21.3771 7.62556 21.7676L8.23165 22.3737C8.62217 22.7642 9.25534 22.7642 9.64586 22.3737L14.9997 17.0199L20.3539 22.374C20.7444 22.7645 21.3775 22.7645 21.7681 22.374L22.3742 21.7679C22.7647 21.3774 22.7647 20.7442 22.3742 20.3537L17.02 14.9996L22.3738 9.64579C22.7643 9.25526 22.7643 8.6221 22.3738 8.23157L21.7677 7.62548Z" fill="#333"></path>
                                </svg>
                            </span>
                        </IconButton>
                    </div>
                    <div className="titles">
                        <h3 className="title3 title">My Content</h3>
                    </div>
                </header>
                <div className="content">
                    <div className="subsection-header-collections" style={{display: 'none'}}>
                        <h4 className="collections-header-label title4">Folders
                            <span className="content-count">(0)</span>
                        </h4>
                    </div>
                    <Button type="button" fullWidth color="google" onClick={() => this.onNewFolderModal(true)} style={{display: 'none'}}>
                        <span className="x-icon plus-icon huntTheme">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.99926 1.3335C7.47327 1.3335 7.04688 1.75989 7.04688 2.28588V7.04777H2.28539C1.7594 7.04777 1.33301 7.47416 1.33301 8.00015C1.33301 8.52613 1.7594 8.95253 2.28539 8.95253H7.04688V13.7144C7.04688 14.2404 7.47327 14.6668 7.99926 14.6668C8.52524 14.6668 8.95164 14.2404 8.95164 13.7144V8.95253H13.714C14.2399 8.95253 14.6663 8.52613 14.6663 8.00015C14.6663 7.47416 14.2399 7.04777 13.714 7.04777H8.95164V2.28588C8.95164 1.75989 8.52524 1.3335 7.99926 1.3335Z" fill="inherit"></path>
                            </svg>
                        </span> 
                        <span>New Folder</span>
                    </Button>
                    <div className="content-lists">
                        <div className="subsection-header-content">
                            <Link to="/" onClick={(e) => this.props.openSection(e,'allcontent')}>
                                <h4 className="content-header-label title4">
                                    <span>All Content</span>
                                    <span className="content-count">({this.getMarkupsCount(markupsList)})</span>
                                </h4>
                            </Link>
                        </div>
                        <div className="subsection-header-content">
                            <Link to="/" onClick={(e) => this.props.openSection(e,'contentTypeList','waypoint')}>
                                <h4 className="content-header-label title4">
                                    <span>Waypoints</span> 
                                    <span className="content-count">({markupsCount.waypoints})</span>
                                </h4>
                            </Link>
                        </div>
                        <div className="subsection-header-content">
                            <Link to="/" onClick={(e) => this.props.openSection(e,'contentTypeList','shape')}>
                                <h4 className="content-header-label title4">
                                    <span>Areas</span> 
                                    <span className="content-count">({markupsCount.shapes})</span>
                                </h4>
                            </Link>
                        </div>
                        <div className="subsection-header-content">
                            <Link to="/" onClick={(e) => this.props.openSection(e,'contentTypeList','line')}>
                                <h4 className="content-header-label title4">
                                    <span>Lines</span> 
                                    <span className="content-count">({markupsCount.lines})</span>
                                </h4>
                            </Link>
                        </div>
                        <div className="subsection-header-content">
                            <Link to="/" onClick={(e) => this.props.openSection(e,'contentTypeList','track')}>
                                <h4 className="content-header-label title4">
                                    <span>Tracks</span> 
                                    <span className="content-count">({markupsCount.tracks})</span>
                                </h4>
                            </Link>
                        </div>
                    </div>
                </div>
                {
                    newFolderModal ?
                        <NewFolderModal 
                            open={newFolderModal} 
                            saving={false} 
                            onClose={() => this.onNewFolderModal(false)} 
                            onSuccess={() => this.onNewFolderModal(false)}
                        />
                    :
                        <></>
                }
            </div>
        );
    }
}

MyContent.propTypes = {
  classes: PropTypes.object
};

export default withStyles(contentStyle)(MyContent);
