import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './app/views/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store';
import "./app/assets/scss/material-kit-pro-react.scss?v=1.7.0";
let store = configureStore();

const rootElement = document.getElementById('my-root');
function renderApp(){
    if (rootElement.hasChildNodes()) {
        hydrate(
            <Provider store={store}>
                <App store={store}/>
            </Provider>,
            rootElement
        );
    } else {
        render(
            <Provider store={store}>
                <App store={store}/>
            </Provider>,
            rootElement
        );
    }
}

store.subscribe(renderApp);
renderApp();

reportWebVitals();
