
import {
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  coloredShadow,
  whiteColor,
  main,
} from "./material-kit-pro-react.jsx";

const sidebarStyle = theme => ({
    container,
    mlAuto,
    mrAuto,
    description,
    coloredShadow,
    cardTitle,
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    sidebarSection: {
        width: "100px",
        flexShrink: "0",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        background: whiteColor,
        position: "absolute",
        top: "0",
        left: "0px",
        bottom: "0",
        zIndex: "1",
        [theme.breakpoints.down("xs")]: {
            width: "80px",
        },
        "& .sd-nav-logo": {
            padding: "14px 10px",
            background: "#f5f5f5",
            borderBottom: "3px solid #f30",
            position: "sticky",
            top: "0px",
            "& .x-logo": {
                fontWeight: "bold"
            }
        },
        "& .sd-siderbar-menu" :{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            "& .nav-link": {
                fontFamily: "Roboto",
                fontSize: "0.6875rem",
                lineHeight: "1.4545454545",
                fontWeight: "500",
                padding: "20px 15px",
                color: "#333",
                textAlign: "center",
                width: "100%",
                display: "block",
                [theme.breakpoints.down("xs")]: {
                    padding: "20px 5px",
                },
                "&:hover": {
                    background: "#f5f5f5",
                },
                "&.account-settings-btn": {
                    "&:hover": {
                        background: "transparent",
                    },
                },
                "& .nav-icon": {
                    paddingBottom: "10px",
                    display: "block"
                },
                "&.sd-uhuntbooking-menu": {
                    textDecoration: "none",
                    backgroundColor: "#e70000",
                    color: whiteColor,
                    "& svg": {
                        fill: whiteColor
                    }
                }
            },
            "& .accordion-link": {
                fontFamily: "Roboto",
                fontSize: "0.6875rem",
                lineHeight: "1.4545454545",
                fontWeight: "400",
                padding: "20px 10px",
                background: "#f5f5f5",
                color: "#333",
                display: "flex",
                cursor: "pointer",
                borderBottom: "solid 1px #e2e2e2",
                textAlign: "left",
                [theme.breakpoints.down("xs")]: {
                    padding: "20px 5px",
                },
                "&:hover": {
                    backgroundColor: "#e2e2e2",
                },
                "& .accordion-icon": {
                    marginRight: "4px",
                    width: "12px",
                    height: "12px",
                    display: "flex",
                    alignItems: "flex-end",
                },
            },
            "& .nav-spacer": {
                flexGrow: "1"
            }
        },
        "& .account-settings-btn": {
            cursor: "pointer",
            "& .profile-pic": {
                width: "30px",
                height: "30px",
                borderRadius: "50%"
            },
            "& .text": {
                padding: "8px 0",
            },
        },
    },
});

export default sidebarStyle;
