
import {
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  coloredShadow,
  whiteColor,
  main,
} from "./material-kit-pro-react.jsx";
import customSelectStyle from "./material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const contentStyle = theme => ({
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    container,
    mlAuto,
    mrAuto,
    description,
    coloredShadow,
    cardTitle,
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    popoverContent: {
        display: "flex",
        flexDirection: "column",
        background: whiteColor,
        borderRadius: "10px",
        zIndex: "5",
        height: "auto",
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        boxShadow: ",0 0.25rem 0.3125rem 0 rgb(0 0 0 / 20%), 0 0.4375rem 0.625rem 0 rgb(0 0 0 / 14%), 0 0.125rem 1rem 0 rgb(0 0 0 / 12%)",
        padding: "0",
        width: "375px",
        [theme.breakpoints.down("xs")]: {
            width: "320px"
        },
        "& header": {
            flex: "0 0 auto",
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "flex-start",
            borderRadius: "5px 5px 0 0",
            padding: "20px 50px 5px 20px",
            "& .close": {
                position: "absolute",
                top: "0",
                right: "0",
                "& button, button:hover": {
                    paddingTop: "5px",
                    background: "transparent"
                }
            },
            "& .titles": {
                flex: "2 1 auto",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "flex-end",
                minHeight: "15px",
                "& .title": {
                    margin: "0",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "330px",
                    fontSize: "1.5rem",
                    lineHeight: "1.25",
                    letterSpacing: "-0.02em",
                }
            }
        },
        "& .content": {
            padding: "15px 20px 20px",
            overflowY: "visible",
            overflowX: "hidden",
            width: "100%",
            "& .subsection-header-collections": {
                display: "flex",
                flexDirection: "row",
                paddingBottom: "10px"
            },
            "& .collections-header-label": {
                flex: "2",
                color: "#333",
                margin: "14px 0",
            },
            "& .content-count": {
                color: "#777",
                marginLeft: "4px",
            },
            "& .content-lists": {
                paddingTop: "10px"
            },
            "& .subsection-header-content": {
                display: "flex",
                flexDirection: "row",
                borderBottom: "2px solid #e2e2e2",
                "&:last-child": {
                    borderBottom: "none",
                }
            },
            "& .content-header-label": {
                color: "#333",
                margin: "14px 0",
                display: "flex"
            },
        },
        "& .content-options": {
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 20px 6px",
            marginBottom: "10px",
            flexDirection: "column",
            "& .option-button__label": {
                marginRight: "10px",
                marginLeft: "4px",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden"
            },
            "& .map-filter-option": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
                margin: "20px -20px 0",
                borderTop: "solid 1px #e2e2e2",
                borderBottom: "solid 1px #e2e2e2",
                "& .option-button__label": {
                    alignSelf: "center",
                    marginLeft: "0",
                }
            },
            "&.new-buttons": {
                marginBottom: "0",
                "& .map-filter-option": {
                    margin: "0 -20px 10px",
                }
            },
            "& .option-container": {
                position: "relative",
                width: "100%",
                display: "flex",
                // justifyContent: "space-between",
                justifyContent: "flex-end"
            },
            "& .left-buttons": {
                "& button": {
                    background: "#e2e2e2",
                }
            },
            "& .right-buttons": {
                "& button": {
                    border: "solid 2px #f5f5f5",
                    boxShadow: "none",
                    padding: "0 10px",
                    width: "auto",
                    height: "32px",
                    minWidth: "auto",
                    borderRadius: "10px",
                    fontSize: ".75rem",
                    marginLeft: "8px",
                    "& svg": {
                        width: "12px",
                        height: "12px"
                    }
                }
            },
            "& .toggle": {
                cursor: "pointer",
                width: "45px",
                height: "24px",
                "& .MuiFormControlLabel-root": {
                    marginLeft: "0px"
                },
                "& .MuiSwitch-root": {
                    paddingTop: "4px",
                    paddingBottom: "4px"
                },
                "& .MuiSwitch-switchBase": {
                    paddingTop: "2px",
                    paddingBottom: "2px"                
                }
            }
        },
        "& .selection-labels": {
            display: "flex",
            justifyContent: "space-between",
            padding: "0 20px",
            color: "#222",
            "&.selection-labels-right": {
                justifyContent: "flex-end",
            },
            "& .selection-count-label": {
                paddingTop: "6px",
            }
        },
        "& .body1": {
            fontFamily: "Roboto",
            fontSize: "0.875rem",
            lineHeight: "1.4285714286",
            fontWeight: "400"
        },
        "& .title4": {
            fontSize: "1.125rem",
            lineHeight: "1.3333333333",
        },
        "& .title5": {
            fontSize: "1rem",
            lineHeight: "1.25",
            margin: "0 0 .75em",
        },
        "& .subtitle3": {
            letterSpacing: "-0.75px",
            fontSize: "0.875rem",
            lineHeight: "1.4285714286",
        },
        "& .subtitle4": {
            letterSpacing: "-0.75px",
            fontSize: "0.6875rem",
            lineHeight: "1.4545454545",
        },
        "& .metadata3": {
            fontSize: "0.6875rem",
            lineHeight: "1.4545454545",
            fontWeight: "400",
        },
        "& #my-content": {
            // height: "calc(100vh - 125px)",
            height: "100vh",
            overflowY: "auto",
            // marginBottom: "90px",
            marginBottom: "0px",
            "& .my-content-container": {
                margin: "0 20px",
            }
        },
        "& .markup-items": {
            borderBottom: "1px solid #e2e2e2",
            "&:last-child": {
                borderBottom: "none"
            },
            "& .MuiAccordion-root": {
                borderRadius: "0px",
                boxShadow: "none"
            },
            "& .MuiAccordionDetails-root": {
                padding: "0px",
                display: "block"
            }
        },
        "& .content-type-header": {
            margin: "5px 0",
            height: "50px",
            paddingTop: "10px",
        },
        "& .markup-header": {
            width: "100%",
            marginTop: "4px",
            "& .MuiAccordionSummary-root": {
                padding: "0px",
                minHeight: "auto",
            },
            "& .MuiAccordionSummary-content": {
                margin: "0px"
            },
            "& .MuiIconButton-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
                marginTop: "-12px"
            },
            "& .markup-count": {
                color: "#a5a5a5",
                marginLeft: "4px"
            }
        },
        "& .footer-actions": {
            position: "absolute",
            display: "flex",
            gap: "10px",
            bottom: "0",
            padding: "20px",
            width: "100%",
            background: whiteColor,
            "& .import-button": {
                flex: "4",
                "& button": {
                    background: "#e2e2e2"
                }
            }
        },
        "& .markup-item": {
            display: "flex",
            alignItems: "center",
            height: "55px",
            marginBottom: "4px",
            "& .markup-fields": {
                cursor: "pointer",
                width: "100%",
            }
        },
        "& .markup-list-item-fields": {
            display: "flex",
            alignItems: "center",
            "& .markup-icon-container": {
                marginRight: "10px",
                width: "32px",
                height: "32px",
                fontSize: "1rem",
                textAlign: "center",
                borderRadius: "50%",
            },
            "& .markup-label": {
                width: "200px",
                paddingRight: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            },
            "& .markup-header-label": {
                color: "#222",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }
        },
        "& .markup-icon": {
            display: "inline-block",
            textAlign: "center",
            borderRadius: "50%",
            "& svg": {
                fill: whiteColor,
            }
        },
        "& .icon-size--small": {
            width: "32px",
            height: "32px",
            border: "2px solid",
            "& svg": {
                marginTop: "3px",
                width: "16px",
                height: "16px",
            }
        },
        "& .color-orange": {
            background: "#f30",
            borderColor: "#c92800",
        },
        "& .color-blue": {
            background: "#087aff",
            borderColor: "#0056bb",
        },
        "& .color-cyan": {
            background: "aqua",
            borderColor: "#099",
            "&.markup-icon svg": {
                fill: "#222",
            },
        },
        "& .color-black": {
            background: "#555",
            borderColor: "#000",
        },
        "& .color-white": {
            background: "#555",
            borderColor: "#fff",
        },
        "& .color-purple": {
            background: "#f0f",
            borderColor: "purple",
        },
        "& .color-yellow": {
            background: "#ff0",
            borderColor: "#585650",
            "&.markup-icon svg": {
                fill: "#222",
            },
        },
        "& .color-red": {
            background: "red",
            borderColor: "#7f0000",
        },
        "& .color-brown": {
            background: "#8b4513",
            borderColor: "#53290b",
        },
        "& .color-magenta": {
            background: "magenta",
            borderColor: "#f0f",
        },        
        "& .markup-visibility": {
            padding: "0px",
            background: "transparent"
        },
        "& .markup-list-item__menu": {
            "& .MuiIconButton-root": {
                background: "transparent",
                paddingRight: "0px"
            }
        }
    }
});

export default contentStyle;
