
import {
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  coloredShadow,
  whiteColor,
  main,
  grayColor,
  dangerColor
} from "./material-kit-pro-react.jsx";
import customSelectStyle from "./material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const myaccountStyle = theme => ({
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    container,
    mlAuto,
    mrAuto,
    description,
    coloredShadow,
    cardTitle,
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    popoverContent: {
        display: "flex",
        flexDirection: "column",
        background: whiteColor,
        borderRadius: "10px",
        zIndex: "5",
        height: "auto",
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        boxShadow: ",0 0.25rem 0.3125rem 0 rgb(0 0 0 / 20%), 0 0.4375rem 0.625rem 0 rgb(0 0 0 / 14%), 0 0.125rem 1rem 0 rgb(0 0 0 / 12%)",
        padding: "0",
        width: "375px",
        [theme.breakpoints.down("xs")]: {
            width: "320px"
        },
        "& header": {
            flex: "0 0 auto",
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "flex-start",
            borderRadius: "5px 5px 0 0",
            padding: "20px 50px 5px 20px",
            "& .close": {
                position: "absolute",
                top: "0",
                right: "0",
                "& button, button:hover": {
                    paddingTop: "5px",
                    background: "transparent"
                }
            },
            "& .titles": {
                flex: "2 1 auto",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "flex-end",
                minHeight: "15px",
                "& .title": {
                    margin: "0",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "330px",
                    fontSize: "1.5rem",
                    lineHeight: "1.25",
                    letterSpacing: "-0.02em",
                }
            }
        },
        "& .footer-actions": {
            position: "static",
            display: "flex",
            gap: "10px",
            bottom: "0",
            paddingTop: "20px",
            width: "100%",
            background: whiteColor,
            "& .import-button": {
                flex: "4",
                "& button": {
                    background: "#e2e2e2"
                }
            }
        },
        "& .myaccount-content": {
            textAlign: "center",
            padding: "0 20px 20px 20px",
            "& .subtitle1": {
                fontWeight: "400",
                fontSize: "1.3125rem",
                lineHeight: "1.3333333333",  
            },
            "& .body1": {
                fontSize: "0.875rem",
                lineHeight: "1.4285714286",
                fontWeight: "400",
                color: grayColor[10],
                paddingBottom: "12px",
            },
            "& .manage-account": {
                textAlign: "left",
                "& hr": {
                    margin: "5px 0",
                },
                "& a": {
                    color: dangerColor[0]
                }
            },
            "& .metadata3": {
                paddingTop: "28px",
                color: "#8b8b8b",
                fontSize: "0.6875rem",
                lineHeight: "1.4545454545",
                fontWeight: "400",
            },
        },
        "& .change-password": {
            padding: "0 20px 20px 20px",
            "& .password-errors": {
                fontSize: "12px",
                marginBottom: "10px"
            }
        },
        "& .editprofile-content": {
            "& .photo-links": {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "& .uploadPhotoInput": {
                    "&  .MuiFormControl-root":{
                        margin: "0px",
                        padding: "0px",
                        "& .MuiInput-formControl":{
                            "&:before": {
                                display: "none"
                            }
                        },
                        "& input": {
                            display: "none"
                        }
                    }
                },
                "& button": {
                    bottom: "0px",
                    right: "0px",
                    padding: "5px",
                    width: "auto",
                    height: "auto",
                    minWidth: "auto",
                    margin: "0px",
                    textTransform: "Capitalize",
                    color: dangerColor[0],
                    fontSize: "16px"
                },
                "& .removePhoto": {
                    "&::after": {
                        content: "'|'",
                        paddingRight: "5px",
                        marginLeft: "5px",
                    }
                }
            },
        },
        "& .user-icon": {
            width: "120px",
            height: "120px",
            lineHeight: "120px",
            fontSize: "40px",
            fontWeight: "bold",
            margin: "auto",
            marginBottom: "8px",
            border: "5px solid #d6d3ce",
            borderRadius: "100%",
            "& .sd_loader": {
                height: "120px"
            },
            "& .avatar-img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                verticalAlign: "top",
                display: "block",
                borderRadius: "100%",
            },
        },
    },
});

export default myaccountStyle;
