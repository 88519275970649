
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Popover from '@material-ui/core/Popover';
import Line from "./Tools/Line.js";
import Area from "./Tools/Area.js";
import Waypoint from "./Tools/Waypoint.js";

import toolsStyle from "../../../assets/jss/toolsStyle.jsx";

class ToolsPopver extends React.Component {
    constructor(props) {
        super(props);

        this.state = {            
            section: 'mycontent',
            contentType: '',
        };
    }
    openSection(event, section, contentType = null){
        event.preventDefault();
        
        this.setState({
            section: section,
            contentType: contentType
        })
    }
    getSection(){
        const { section } = this.props;

        switch(section){
            case "line":   
                return <Line 
                    onClose = {this.props.onClose}
                    design={this.props.lineDesign}
                    updateDesign={(design, type) => this.props.updateDesign(design, type)}
                    formData={this.props.lineFormData}
                    updateFormData={(formData, type) => this.props.updateFormData(formData, type)}
                    formSubmit={(type) => this.props.formSubmit(type)}
                    formButtonStatus={this.props.formButtonStatus}
                />;
            case "area":   
                return <Area 
                    onClose = {this.props.onClose}
                    design={this.props.shapeDesign}
                    updateDesign={(design, type) => this.props.updateDesign(design, type)}
                    formData={this.props.shapeFormData}
                    updateFormData={(formData, type) => this.props.updateFormData(formData, type)}
                    formSubmit={(type) => this.props.formSubmit(type)}
                    formButtonStatus={this.props.formButtonStatus}
                />;
            case "waypoint":   
            case "photo":   
                return <Waypoint 
                    onClose = {this.props.onClose}
                    design={this.props.waypointDesign}
                    updateDesign={(design, type) => this.props.updateDesign(design, type)}
                    formData={this.props.waypointFormData}
                    updateFormData={(formData, type) => this.props.updateFormData(formData, type)}
                    formSubmit={(type) => this.props.formSubmit(type)}
                    formButtonStatus={this.props.formButtonStatus}
                    section={section}
                />;
            default:      
                return <></>
        }
    }
    render() {
        const { id, popupOpen, anchorEl } = this.props;
        const { section } = this.state;

        return (
            <Popover
                id={id}
                open={popupOpen}
                anchorEl={anchorEl}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: 500 }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                { this.getSection(section) }
            </Popover>
        );
    }
}

ToolsPopver.propTypes = {
  classes: PropTypes.object
};

export default withStyles(toolsStyle)(ToolsPopver);