import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import { Slide, Dialog, DialogTitle, DialogActions } from "@material-ui/core";

import photoErrorModalStyle from "../../../assets/jss/modals/photoErrorModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const PhotoErrorModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
    }
    render() {
        const { classes, title, buttonText } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle+" "+classes.textCenter}>{title}</h4> 
                </DialogTitle>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <Button onClick={() => this.props.onClose()} color="github">
                        {buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

PhotoErrorModal.defaultProps = {
    open: false,
}
PhotoErrorModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
export default withStyles(photoErrorModalStyle)(PhotoErrorModal);