import React, { Component } from 'react';
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import RouterComponent from './components/Router';
import AuthApi from '../assets/js/utils/Auth';
import { helper } from '../assets/js/utils/Element';

import '../assets/scss/App.scss';

const history = createBrowserHistory();

class App extends Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.loading = this.props.loading;
        this.state = this.store.getState();
        this.loaded = false;
    }
    componentDidMount(){   
        this.loaded = true;

        const that = this;
        this.listenHistory();
        const hasAccessToken = AuthApi.hasAccessToken();

        if(hasAccessToken === false){
            this.loading = false
            that.store.dispatch({type: 'UPDATE_STATE', state: {}}); 
        }
        AuthApi.validateToken().then((isValidToken) => {
            this.loading = false;
            if(hasAccessToken === true && isValidToken === false){
                that.logout();
            }else if(hasAccessToken === true){
                let url = that.store.getState().url;
                
                that.store.dispatch({
                    type: "LOGIN",
                    state: {
                        authorized: true,
                        user: isValidToken,
                        url: url
                    }
                });
            }
        }).catch(err => {
            this.loading = false;
            that.store.dispatch({type: 'UPDATE_STATE',state: {}});
        });

        if(process.env.REACT_APP_ENV !== "production"){
            //console.log(process.env);
        }

        const returnUrl = helper.getParam("rte");
        if(returnUrl && returnUrl.length > 0 && returnUrl !== "null"){
            this.store.dispatch({type: 'UPDATE_STATE',state: {url: "/"+decodeURIComponent(returnUrl) }});
            history.push(returnUrl);
        }
    }
    logout(){
        history.push("/");
        AuthApi.logout();
    }
    listenHistory(){
        const that = this;
        history.listen(function (location) {
            that.store.dispatch({type: 'UPDATE_STATE',state: {url: location.pathname }});           
        });
    }

    render(){
        if(this.loaded === false){
            return <></>;
        }
        return (
            <Router history={history}>
                <div className="App">
                    <div className="main-container" id="global_wrapper">
                        <RouterComponent history={history} store={this.store} />
                    </div>
                </div>
            </Router>   
        )
    }
}

export default App;