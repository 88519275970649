import Config from '../../../../Config';
import Cookie from './Cookie';
import axios from 'axios';

const ApiHelper = {
    countries: null,
    getDefaultMemberObject(){
        return  {
            first_name: "",
            last_name: "",
            email: "",
            created_at: "",
        };
    },
    prepareMemberObject(member){
        const defaultObject = this.getDefaultMemberObject();
        let memberObject = Object.assign({}, defaultObject); //Create copy to avoid reference copy
        memberObject = Object.assign(memberObject, member);
        for(var attrib in memberObject){
            try{
                if(typeof(memberObject[attrib]) === "string"){
                    memberObject[attrib] = memberObject[attrib].trim();
                }                
            }catch(e){ }
        }
        return memberObject;
    },
    signUp(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"signup";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    confirmSignUp(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/verify";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    resendCode(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/resend";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    forgot(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/forgot";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    reset(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"auth/reset";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    login(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"login";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    revokeAccessToken(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"token/revoke";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200 || res.data.status_code === 204){
                    resolve(res.data);
                }else{
                    reject(res.data);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.error === true){
                    reject(err.response.data);
                    return;
                }
                reject(err);
            });
        });
    },
    getCookies(){
        const cookies = Cookie.getAll();
        return encodeURIComponent(JSON.stringify(cookies));
    },
    updateUser(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"user/profile";         
            axios.put(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    updateLoginStatus(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/updateloginstatus";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updatePassword(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/password";            
            axios.put(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    deleteUser(userId, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/delete/"+userId;            
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    uploadUserPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/photo";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(function (err) {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
            
        });
    },
    removeUserPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"user/removePhoto";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(function (err) {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
            
        });
    },
    uploadWaypointPhoto(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"waypoint/photo";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(function (err) {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
            
        });
    },
    removeWaypointPhotos(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"waypoint/removePhoto";
            axios({
                method: 'post',
                url: ApiUrl,
                data: requestData,
                headers: headers
            }).then(function (res) {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(function (err) {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
            
        });
    },
    createMarkup(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"markups";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    deleteMarkup(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"markups/delete";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    showHideMarkup(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            const ApiUrl = Config.getApiUrl()+"markups/changeStatus";
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getMarkups(type, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"markups/"+type;
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    getMarkup(id, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = Config.getApiHeaders();
            let ApiUrl = Config.getApiUrl()+"markup/"+id;
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.status_code === 200){
                    resolve(res.data.body);
                }else{
                    reject(res.data.body);
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && err.response.data.status_code !== 200){
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
}

export default ApiHelper;
export { ApiHelper };