import Config from '../../../../Config';

let cookieObj = {
    options: {
		path: '/',
		//domain: window.location.host,
        // domain: Config.getSessionDomain(),
		duration: 1209600,
		// secure: Config.getSessionSecure(),
		document: document,
		encode: true
    },
    write(key, value, domain = null){
		if (this.options.encode) value = encodeURIComponent(value);
		//if (this.options.domain) value += '; domain=' + this.options.domain;
        if(domain){
            value += '; domain=' + domain;
        }else{
            value += '; domain=' + Config.getSessionDomain();
        }
        
		if (this.options.path) value += '; path=' + this.options.path;
		if (this.options.duration){
			var date = new Date();
			date.setTime(date.getTime() + this.options.duration * 24 * 60 * 60 * 1000);
			value += '; expires=' + date.toGMTString();
		}
        if (Config.getSessionSecure() === true || Config.getSessionSecure() === 'true') value += '; secure';
		this.options.document.cookie = key + '=' + value;
		return this;
    },
    read(key){
		var value = this.options.document.cookie.match('(?:^|;)\\s*' + key + '=([^;]*)');
		return (value) ? decodeURIComponent(value[1]) : null;
	},

	dispose(key){
        this.options.duration = -1;
        this.write(key,'');
        this.options.duration = false;
		return this;
	},
	getAll(){
		var cookies = { };
    	if (document.cookie && document.cookie !== '') {
			var split = document.cookie.split(';');
			for (var i = 0; i < split.length; i++) {
				var name_value = split[i].split("=");
				name_value[0] = name_value[0].replace(/^ /, '');
				if(name_value[1].trim().length > 0){
					cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(name_value[1]);
				}				
			}
		}
		return cookies;
	}
};

export default cookieObj