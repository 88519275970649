
import {
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  coloredShadow,
  main,
} from "./material-kit-pro-react.jsx";

const homepageStyle = theme => ({
    container,
    mlAuto,
    mrAuto,
    description,
    coloredShadow,
    cardTitle,
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    contentSection: {
        "& .map-section": {
            position: "relative",
            overflow: "hidden",
            zIndex: "0",
            width: "100%",
            height: "100vh",
        },
    }
});

export default homepageStyle;