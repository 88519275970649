
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../CustomButtons/Button.jsx";
import CustomInput from "../../CustomInput/CustomInput.jsx";

import toolsStyle from "../../../../assets/jss/toolsStyle.jsx";

class Area extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: this.props.formData,
            lineColors: this.getLineColors(),
            areaColors: this.getAreaColors(),
            styles: [ 'solid', 'dash', 'dot' ],
            weights: [ 'two', 'four', 'six' ],
            design: this.props.design
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            formData: props.formData,
            design: props.design
        });
    }
    getLineColors(){
        let colors = [
            'rgba(255, 51, 0, 1.0)',
            'rgba(8, 122, 255, 1.0)',
            'rgba(0, 255, 255, 1.0)',
            'rgba(0, 0, 0, 1.0)',
            'rgba(255, 255, 255, 1.0)',
            'rgba(128, 0, 128, 1.0)',
            'rgba(255, 255, 0, 1.0)',
            'rgba(255, 0, 0, 1.0)',
            'rgba(139, 69, 19, 1.0)',
            'rgba(255, 0, 255, 1.0)'
        ];               

        return colors;
    }
    getAreaColors(){
        let colors = [
            'rgba(74, 144, 226, 1.0)',
            'rgba(212, 83, 47, 1.0)',
            'rgba(113, 95, 46, 1.0)',
            'rgba(193, 76, 90, 1.0)',
            'rgba(238, 255, 125, 1.0)',
            'rgba(42, 139, 97, 1.0)',
            'rgba(255, 255, 255, 1.0)',
            'rgba(153, 255, 255, 1.0)',
            'rgba(80, 80, 80, 1.0)',
            'rgba(232, 118, 255, 1.0)'
        ];               

        return colors;
    }
    handleSubmit(){
        this.props.formSubmit('shape')
    }
    handleChange(e, name){
        let formData = {
            ...this.state.formData,
            [name]: e.target.value
        };

        this.setState({
            formData: formData
        });

        this.props.updateFormData(formData, 'shape');
    }
    handleChangeColor(color, name){
        let design = {
            ...this.state.design,
            [name] : color
        };

        this.setState({
            design: design
        });

        this.props.updateDesign(design, 'shape');
    }
    handleStyleChange(style){
        let design = {
            ...this.state.design,
            style : style
        };

        this.setState({
            design: design
        });

        this.props.updateDesign(design, 'shape');
    }
    handleWeightChange(weight){
        let design = {
            ...this.state.design,
            weight: weight
        };

        this.setState({
            design: design
        });

        this.props.updateDesign(design, 'shape');
    }
    getColorLi(color, key){
        return (
            <li className={"color "+(this.state.design.color === color ? 'selected' : '')} key={key} onClick={() => this.handleChangeColor(color, 'color')} style={{backgroundColor: color}}></li>
        )
    }
    getAreaColorLi(color, key){
        return (
            <li className={"color "+(this.state.design.fillColor === color ? 'selected' : '')} key={key} onClick={() => this.handleChangeColor(color, 'fillColor')} style={{backgroundColor: color}}></li>
        )
    }
    getStyleLi(style, key){
        return (
            <li className={"style "+(this.state.design.style === style ? 'selected' : '')} key={key} onClick={() => this.handleStyleChange(style)}>
                <div className={"inner-"+style}></div>
            </li>
        )
    }
    getWeightLi(weight, key){
        return (
            <li className={"weight "+weight+(this.state.design.weight === weight ? ' selected' : '')} key={key} onClick={() => this.handleWeightChange(weight)}></li>
        )
    }
    render() {
        const { classes } = this.props;
        const { title, notes } = this.state.formData;

        return (
            <div className={classes.popoverContent}>
                <header>
                    <div className="close">
                        <IconButton title="Close card" onClick={this.props.onClose}>
                            <span className="x-icon">
                                <svg width="25" height="30" viewBox="0 0 30 30" fill="#333" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.7677 7.62548C21.3772 7.23496 20.744 7.23496 20.3535 7.62548L14.9997 12.9792L9.64623 7.62577C9.25571 7.23524 8.62254 7.23524 8.23202 7.62577L7.62593 8.23186C7.23541 8.62238 7.23541 9.25555 7.62593 9.64607L12.9794 14.9996L7.62556 20.3534C7.23503 20.7439 7.23503 21.3771 7.62556 21.7676L8.23165 22.3737C8.62217 22.7642 9.25534 22.7642 9.64586 22.3737L14.9997 17.0199L20.3539 22.374C20.7444 22.7645 21.3775 22.7645 21.7681 22.374L22.3742 21.7679C22.7647 21.3774 22.7647 20.7442 22.3742 20.3537L17.02 14.9996L22.3738 9.64579C22.7643 9.25526 22.7643 8.6221 22.3738 8.23157L21.7677 7.62548Z" fill="#333"></path>
                                </svg>
                            </span>
                        </IconButton>
                    </div>
                </header>
                <div className="popover-content">
                    <div className="edit-markup-content">
                        <form>
                            <div className="markup-scroll-content">
                                <CustomInput
                                    id="input-titleText"
                                    labelText="Area Name"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e,'title'),
                                        value: title,
                                        name: "title",
                                        type: "text",
                                    }}
                                    color="info"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses
                                    }}
                                />
                                <div className="edit-draw-type-section markup-card-section">
                                    <div className="markup-card-section-header">
                                        <label className="draw-type-label" htmlFor="edit-type">Area Type</label>
                                    </div>
                                    <div className="types">
                                        <Button id="free-draw-btn" size="sm" type="button" color="transparent">
                                            <div className="draw-type-icon">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 11.0837V14H2.88423L11.3908 5.39897L8.50657 2.48271L0 11.0837ZM13.6213 3.14373C13.9212 2.84044 13.9212 2.35051 13.6213 2.04722L11.8215 0.227468C11.5216 -0.0758228 11.037 -0.0758228 10.737 0.227468L9.32954 1.6506L12.2138 4.56686L13.6213 3.14373Z" fill="inherit"></path>
                                                </svg>
                                            </div>
                                            <span>
                                                Free Draw
                                            </span>
                                        </Button>
                                        <Button id="click-draw-btn" size="sm" type="button" color="reddit">
                                            <div className="draw-type-icon">
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.7971 8.8366L7.56217 5.60236C7.54413 5.5845 7.53026 5.56288 7.52154 5.53904C7.51282 5.51521 7.50946 5.48974 7.51171 5.46446C7.51397 5.43917 7.52177 5.4147 7.53456 5.39278C7.54735 5.37086 7.56482 5.35203 7.58573 5.33763L9.66473 3.89619C9.772 3.82186 9.8561 3.71875 9.90735 3.59873C9.9586 3.47871 9.97492 3.34665 9.95443 3.21777C9.93393 3.08888 9.87747 2.9684 9.79152 2.8702C9.70557 2.77199 9.59365 2.70004 9.46861 2.66265L0.66888 0.0216166C0.578997 -0.00512991 0.483557 -0.00709332 0.39265 0.0159339C0.301742 0.0389611 0.218746 0.0861234 0.152435 0.152435C0.0861234 0.218746 0.0389611 0.301742 0.0159339 0.39265C-0.00709332 0.483557 -0.00512991 0.578997 0.0216166 0.66888L2.66265 9.47C2.70013 9.59498 2.77214 9.70685 2.87038 9.79271C2.96863 9.87858 3.08912 9.93497 3.218 9.95539C3.34688 9.9758 3.4789 9.95942 3.59887 9.90813C3.71885 9.85683 3.82191 9.7727 3.89619 9.66543L5.33694 7.58642C5.35136 7.56557 5.37018 7.54814 5.39207 7.53535C5.41396 7.52257 5.43839 7.51474 5.46363 7.51243C5.48888 7.51011 5.51433 7.51337 5.53818 7.52196C5.56203 7.53056 5.5837 7.54428 5.60167 7.56217L8.8366 10.7971C8.96655 10.927 9.14279 11 9.32655 11C9.51031 11 9.68654 10.927 9.8165 10.7971L10.7971 9.8165C10.927 9.68654 11 9.51031 11 9.32655C11 9.14279 10.927 8.96655 10.7971 8.8366Z" fill="inherit"></path>
                                                </svg>
                                            </div>
                                            <span>
                                                Click to Draw
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="edit-color-section markup-card-section">
                                    <div className="markup-card-section-header">
                                        <label htmlFor="edit-color">Line Color</label>
                                    </div>
                                    <ul className="colors">
                                        {
                                            this.state.lineColors.map((color, key) => {
                                                return this.getColorLi(color, key)
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="edit-color-section markup-card-section">
                                    <div className="markup-card-section-header">
                                        <label htmlFor="edit-color">Area Color</label>
                                    </div>
                                    <ul className="colors">
                                        {
                                            this.state.areaColors.map((color, key) => {
                                                return this.getAreaColorLi(color, key)
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="edit-style-section markup-card-section">
                                    <div className="area-style-header markup-card-section-header">
                                        <label className="area-style-label" htmlFor="edit-style">Style</label>
                                    </div>
                                    <ul className="styles">
                                        {
                                            this.state.styles.map((style, key) => {
                                                return this.getStyleLi(style, key)
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="edit-weight-section markup-card-section">
                                    <div className="area-weight-header markup-card-section-header">
                                        <label className="area-weight-label" htmlFor="edit-weight">Weight</label>
                                    </div>
                                    <ul className="weights">
                                        {
                                            this.state.weights.map((weight, key) => {
                                                return this.getWeightLi(weight, key)
                                            })
                                        }
                                    </ul>
                                </div>
                                <CustomInput
                                    id="input-notes"
                                    labelText="Notes"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e,'notes'),
                                        value: notes,
                                        name: "notes",
                                        type: "text",
                                        multiline: true,
                                        rows: 3,
                                    }}
                                    color="info"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses
                                    }}
                                />
                            </div>
                            <div className="edit-actions">
                                <Button type="button" onClick={() => this.handleSubmit()} color="success" disabled={this.props.formButtonStatus === true ? false : true} fullWidth>Save</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Area.propTypes = {
  classes: PropTypes.object
};

export default withStyles(toolsStyle)(Area);
