
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import MyContentPopver from './MyContentPopver';
import ToolsPopver from './ToolsPopver';
import MyAccountPopover from './MyAccountPopover';
import MarkupDetailPopover from './MarkupDetailPopover';
import contentStyle from "../../../assets/jss/contentStyle.jsx";

class Popovers extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
    }
    getSection(){
        const { id, popupOpen, anchorEl, section, markupsList, markupsCount, popupMarkupItem } = this.props;
        
        switch(section){
            case "mycontent":   
                return <MyContentPopver 
                    id={id}
                    popupOpen={popupOpen}
                    anchorEl={anchorEl}
                    onClose={this.props.onClose}
                    markupsList={markupsList}
                    markupsCount={markupsCount}
                    setEditMarkupItem={(markupItem) => this.props.setEditMarkupItem(markupItem)}
                    setDeleteMarkupItem={(markupItem) => this.props.setDeleteMarkupItem(markupItem)}
                    setShowHideMarkupItem={(markupItem) => this.props.setShowHideMarkupItem(markupItem)}
                    setLoadMarkupItem={(markupItem) => this.props.setLoadMarkupItem(markupItem)}
                    store={this.store}
                />;
            case "myaccount":
                return <MyAccountPopover
                    id={id}
                    popupOpen={popupOpen}
                    anchorEl={anchorEl}
                    onClose={this.props.onClose}
                    store={this.store}
                    history={this.history}
                />;
            case "Point":
            case "Polygon":
            case "LineString":
                return <MarkupDetailPopover
                    id={id}
                    popupOpen={popupOpen}
                    anchorEl={anchorEl}
                    onClose={this.props.onClose}
                    section={section}
                    popupMarkupItem={popupMarkupItem}
                    setDeleteMarkupItem={(markupItem) => this.props.setDeleteMarkupItem(markupItem, true)}
                    setShowHideMarkupItem={(markupItem) => this.props.setShowHideMarkupItem(markupItem)}
                    store={this.store}
                />;
            case "line":
            case "area":
            case "waypoint":
            case "photo":
                return <ToolsPopver
                    id={id}
                    popupOpen={popupOpen}
                    anchorEl={anchorEl}
                    onClose={(tools) => this.props.onClose(true)}
                    section={section}
                    lineDesign={this.props.lineDesign}
                    shapeDesign={this.props.shapeDesign}
                    waypointDesign={this.props.waypointDesign}
                    updateDesign={(design, type) => this.props.updateDesign(design, type)}
                    lineFormData={this.props.lineFormData}
                    shapeFormData={this.props.shapeFormData}
                    waypointFormData={this.props.waypointFormData}
                    updateFormData={(formData, type) => this.props.updateFormData(formData, type)}
                    formSubmit={(type) => this.props.formSubmit(type)}
                    formButtonStatus={this.props.formButtonStatus}
                />;
            default:      
                return <></>
        }
    }
    render() {
        return (
            <>
                { this.getSection() }
            </>
        );
    }
}

Popovers.propTypes = {
  classes: PropTypes.object
};

export default withStyles(contentStyle)(Popovers);
