
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { IconButton, FormControlLabel, Checkbox } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import { lineString, length, polygon, area, convertArea } from "@turf/turf";

import markupDetail from "../../../../assets/jss/markupDetail.jsx";

class LineArea extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;

        this.state = {
            markupItem: this.props.popupMarkupItem,
            markupHide: false,
        }
    }
    componentWillReceiveProps(props){
        let state = {};

        state['markupItem'] = props.popupMarkupItem;        
        if(props.popupMarkupItem.id !== this.state.markupItem.id){
            state['markupHide'] = false;   
        }
        this.setState(state);
    }
    getDate(markupItem){
        let date = new Date(markupItem.updated_at).toLocaleDateString(); 

        return date;
    }
    getTotalLength(markupItem, type){
        let mapData = markupItem.geo_json;

        let line = null;
        if(type === 'Polygon'){
            line = lineString(mapData.geometry.coordinates[0]);
        }else if(type === 'LineString'){
            line = lineString(mapData.geometry.coordinates);
        }

        let lineLength = length(line, {units: 'miles'});
        
        return lineLength.toFixed(1);
    }
    getTotalArea(markupItem){
        let mapData = markupItem.geo_json;

        let polygonArea = polygon(mapData.geometry.coordinates);
        let totalArea = area(polygonArea);
        
        let acersArea = convertArea(totalArea, 'meters', 'acres');

        return acersArea.toFixed(1);
    }
    showHideMarkup(markupItem){
        this.setState({
            markupHide: !this.state.markupHide
        })
        this.props.setShowHideMarkupItem(markupItem);
    }
    render() {
        const { markupHide, markupItem } = this.state;
        const { classes } = this.props;
        let type = markupItem.geo_json.geometry.type;
        
        return (
            <div className={classes.popoverContent}>
                <header>
                    <div className="close">
                        <IconButton title="Close card" onClick={this.props.onClose}>
                            <span className="x-icon">
                                <svg width="25" height="30" viewBox="0 0 30 30" fill="#333" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.7677 7.62548C21.3772 7.23496 20.744 7.23496 20.3535 7.62548L14.9997 12.9792L9.64623 7.62577C9.25571 7.23524 8.62254 7.23524 8.23202 7.62577L7.62593 8.23186C7.23541 8.62238 7.23541 9.25555 7.62593 9.64607L12.9794 14.9996L7.62556 20.3534C7.23503 20.7439 7.23503 21.3771 7.62556 21.7676L8.23165 22.3737C8.62217 22.7642 9.25534 22.7642 9.64586 22.3737L14.9997 17.0199L20.3539 22.374C20.7444 22.7645 21.3775 22.7645 21.7681 22.374L22.3742 21.7679C22.7647 21.3774 22.7647 20.7442 22.3742 20.3537L17.02 14.9996L22.3738 9.64579C22.7643 9.25526 22.7643 8.6221 22.3738 8.23157L21.7677 7.62548Z" fill="#333"></path>
                                </svg>
                            </span>
                        </IconButton>
                    </div>
                    <div className="titles">
                        <h3 className="title3 title">{ markupItem.name }</h3>
                    </div>
                </header>
                <div className="body1 markup-timestamps">{ this.getDate(markupItem) }</div>
                <div className="content">
                    {
                        markupItem.canEdit === true ?
                            <div className="hide-on-map">
                                <div className="checkbox x-grey">
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Checkbox                                                
                                                tabIndex={-1}
                                                onClick={() => this.showHideMarkup(markupItem)}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                                checked={(markupHide === true ? true : false)}
                                            />
                                        }
                                        label="Hide on map"
                                    />
                                </div>
                            </div>
                        :
                            <></>
                    }
                    <ul className="quick-stats">
                        <li className="stat">
                            <div className="metadata2">Total Length</div>
                            <span className="x-distance value" >{ this.getTotalLength(markupItem, type) } <span className="x-distance-unit">mi</span></span>
                        </li>
                        {
                            type === 'Polygon' ?
                                <li className="stat">
                                    <div className="metadata2">Total Area</div>
                                    <span className="x-distance value" >{ this.getTotalArea(markupItem) } <span className="x-distance-unit">ac</span></span>
                                </li>
                            : type === 'LineString' ?
                                <>
                                    <li className="stat" style={{display: 'none'}}>
                                        <div className="metadata2">Elevation gain</div>
                                        <span className="x-distance value">0 <span className="x-distance-unit">ft</span></span>
                                    </li>
                                    <li className="stat" style={{display: 'none'}}>
                                        <div className="metadata2">Elevation loss</div>
                                        <span className="x-distance value">0 <span className="x-distance-unit">ft</span></span>
                                    </li>
                                </>
                            :
                                <></>
                        }
                    </ul>
                    {
                        markupItem.notes && markupItem.notes !== null ?
                            <div className="notes">
                                <h4 className="title4">Notes</h4>
                                <div className="preserve-line-breaks">{markupItem.notes}</div>
                            </div>
                        :
                            <></>
                    }
                </div>
                {
                    markupItem.canEdit === true ?
                        <footer>
                            <ul className="card-toolbar">
                                <li className="toolbar-option">
                                    <IconButton title="Delete" onClick={() => this.props.setDeleteMarkupItem(markupItem)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.7679 18.25H14.7321C14.9732 18.25 15.2143 18.0547 15.2143 17.7812V9.34375C15.2143 9.10938 14.9732 8.875 14.7321 8.875H13.7679C13.4866 8.875 13.2857 9.10938 13.2857 9.34375V17.7812C13.2857 18.0547 13.4866 18.25 13.7679 18.25ZM20.3571 5.125H17.0223L15.6562 2.9375C15.3348 2.42969 14.6116 2 14.0089 2H9.95089C9.34821 2 8.625 2.42969 8.30357 2.9375L6.9375 5.125H3.64286C3.28125 5.125 3 5.4375 3 5.75V6.375C3 6.72656 3.28125 7 3.64286 7H4.28571V20.125C4.28571 21.1797 5.12946 22 6.21429 22H17.7857C18.8304 22 19.7143 21.1797 19.7143 20.125V7H20.3571C20.6786 7 21 6.72656 21 6.375V5.75C21 5.4375 20.6786 5.125 20.3571 5.125ZM9.87054 3.99219C9.91071 3.95312 10.0312 3.875 10.0714 3.875H10.1116H13.8884C13.9286 3.875 14.0491 3.95312 14.0893 3.99219L14.7723 5.125H9.1875L9.87054 3.99219ZM17.7857 20.125H6.21429V7H17.7857V20.125ZM9.26786 18.25H10.2321C10.4732 18.25 10.7143 18.0547 10.7143 17.7812V9.34375C10.7143 9.10938 10.4732 8.875 10.2321 8.875H9.26786C8.98661 8.875 8.78571 9.10938 8.78571 9.34375V17.7812C8.78571 18.0547 8.98661 18.25 9.26786 18.25Z" fill="inherit"></path>
                                        </svg>
                                    </IconButton>
                                    <p>Delete</p>
                                </li>
                            </ul>
                        </footer>
                    :
                        <></>
                }
            </div>
        );
    }
}

LineArea.propTypes = {
  classes: PropTypes.object
};

export default withStyles(markupDetail)(LineArea);
