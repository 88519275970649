
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../CustomButtons/Button.jsx";
import myaccountStyle from "../../../../assets/jss/myaccountStyle.jsx";
import profilePicture from "../../../../assets/img/profile-picture.png";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import LoaderComponent from '../../../components/Loader'
// import { Link } from "@material-ui/core";

import CustomFileInput from "../../../components/CustomFileInput/CustomFileInput";

class EditProfile extends React.Component {
    constructor(props) {
        super(props);

        this.history = this.props.history;
        this.store = this.props.store;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            values: values,
            editInfo: false,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            photo: null,
            uploading: false,
            success: false,
            validation: {
                firstName: '',
                lastName: '',
                email: '',
                isValid: false
            },
        }
        this.newUser = null;

        this.handleChange = this.handleChange.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
    }
    saveUserInfo(e){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            editInfo: false, 
            savingInfo: true,
            showError: false
        });
        this.updateUser(e);
    }
    validateForm(){
        const { values } = this.state;
        let validation = {
            firstName: "success",
            lastName: "success",
            email: "success",
            isValid: true
        };
        if(values.first_name.length <= 2){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(values.last_name.length <= 2){
            validation.lastName = "error";
            validation.isValid = false;
        }
        var emailRegex = /\S+@\S+\.\S+/;
        if(values.email.length <= 0 || !emailRegex.test(values.email)){
            validation.email = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    updateUser(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);

        const source = axios.CancelToken.source();
        Api.updateUser(user.id, values, source).then((data) => {
            this.newUser = data.user;
            if(data.requireCellVerification){
                this.setState({phoneVerificationModal: true, savingInfo: false});
            }else{
                this.setState({
                    savingInfo: false, 
                });
                localStorage.setItem("uhuntmaps_userinfo", JSON.stringify(data.user));
                let newUser = Api.prepareMemberObject(data.user);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
            }
            
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    handleChange(event, name) {
        this.setState({
            values: {
                ...this.state.values, 
                [name]: event.target.value
            }
        });
    }
    handleFileChange(e, name){
        let photo = e.target.files[0];

        var requestData = new FormData();
        requestData.append('photo', photo);

        const source = axios.CancelToken.source();
        this.setState({
            [name]: photo,
            uploading: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.uploadUserPhoto(requestData, source).then(data => {
            that.setState({
                success: true,
                uploading: false
            });
            
            localStorage.setItem("uhuntmaps_userinfo", JSON.stringify(data.user));
            let newUser = Api.prepareMemberObject(data.user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    removeProfilePhoto(e, name){
        var requestData = {};

        const source = axios.CancelToken.source();
        this.setState({
            [name]: '',
            uploading: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.removeUserPhoto(requestData, source).then(data => {
            that.setState({
                success: false,
                uploading: false
            });

            localStorage.setItem("uhuntmaps_userinfo", JSON.stringify(data.user));
            let newUser = Api.prepareMemberObject(data.user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    render() {
        const { classes } = this.props;
        const { savingInfo, validation, values, showError, photo, uploading, success } = this.state;
        const { user } = this.store.getState();

        return (
            <div className={classes.popoverContent}>
                <header>
                    <div className="close">
                        <IconButton title="Close card" onClick={(e) => this.props.openSection(e,'myaccount')}>
                            <span className="x-icon">
                                <svg width="25" height="30" viewBox="0 0 30 30" fill="#333" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.7677 7.62548C21.3772 7.23496 20.744 7.23496 20.3535 7.62548L14.9997 12.9792L9.64623 7.62577C9.25571 7.23524 8.62254 7.23524 8.23202 7.62577L7.62593 8.23186C7.23541 8.62238 7.23541 9.25555 7.62593 9.64607L12.9794 14.9996L7.62556 20.3534C7.23503 20.7439 7.23503 21.3771 7.62556 21.7676L8.23165 22.3737C8.62217 22.7642 9.25534 22.7642 9.64586 22.3737L14.9997 17.0199L20.3539 22.374C20.7444 22.7645 21.3775 22.7645 21.7681 22.374L22.3742 21.7679C22.7647 21.3774 22.7647 20.7442 22.3742 20.3537L17.02 14.9996L22.3738 9.64579C22.7643 9.25526 22.7643 8.6221 22.3738 8.23157L21.7677 7.62548Z" fill="#333"></path>
                                </svg>
                            </span>
                        </IconButton>
                    </div>
                    <div className="titles">
                        <h3 className="title3 title">My Account</h3>
                    </div>
                </header>
                <div className="myaccount-content editprofile-content">
                    <div className="user-icon">
                        {
                            uploading === true ?
                                <LoaderComponent align="center" color="info" />
                            : success === true || user.profile_photo ?
                                <img className={"avatar-img"} src={user.profile_photo} alt={"avatar"} />
                            :
                                <img alt="avatar" src={profilePicture} className="avatar-img" />
                        }
                    </div>
                    <div className="form-fields">
                        <div className="photo-links">
                            {
                                success === true || user.profile_photo ?
                                    <div className={"removePhoto"}>
                                        <Button link={true} onClick={(e) => this.removeProfilePhoto(e, 'photo')}>Delete Photo</Button>
                                    </div>
                                :
                                    <></>
                            }
                            <div className="uploadPhotoInput">
                                <CustomFileInput
                                    id="input-photo"                            
                                    onChange={(e) => this.handleFileChange(e,'photo')}
                                    endButton={{
                                        buttonProps: {
                                            fileButton: true,
                                            link: true
                                        },
                                        text: "Change Photo"
                                    }}
                                    inputProps={{
                                        value: photo,
                                        placeholder: "Photo"
                                    }}
                                    color="info"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </div>
                        </div>
                        {
                            showError ?
                                this.renderErrorMessages()
                            :
                            <></>
                        }
                        <CustomInput
                            success={validation.firstName === "success"}
                            error={validation.firstName === "error"}
                            id="outlined-firstName"
                            labelText="First Name"                                    
                            inputProps={{
                                value: values.first_name,
                                onChange: (e) => this.handleChange(e, 'first_name'),
                                name: "first_name",
                            }}                                    
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </div>
                    <CustomInput
                        success={validation.lastName === "success"}
                        error={validation.lastName === "error"}
                        id="outlined-lastName"
                        labelText="Last Name"                                    
                        inputProps={{
                            value: values.last_name,
                            onChange: (e) => this.handleChange(e, 'last_name'),
                            name: "last_name",
                        }}                                    
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                    <CustomInput
                        success={validation.email === "success"}
                        error={validation.email === "error"}
                        id="input-email"
                        labelText="Email"
                        inputProps={{
                            value: values.email ? values.email : "",
                            onChange: (e) => this.handleChange(e, "email"),
                            name: "email",
                        }}
                        formControlProps={{
                            fullWidth: true,
                            className: classes.formControl
                        }}
                    />
                    <div className="footer-actions">
                        {
                            savingInfo ?
                                <Button fullWidth color="danger">
                                    <LoaderComponent saving={true} align="center" color="white"/>
                                </Button>
                            :
                                <Button fullWidth color="danger" onClick={(e) => this.saveUserInfo(e)}>
                                    <span>Save</span>
                                </Button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

EditProfile.propTypes = {
  classes: PropTypes.object
};

export default withStyles(myaccountStyle)(EditProfile);
