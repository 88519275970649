
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Popover from '@material-ui/core/Popover';
import MyContent from "./Mycontent/MyContent.js";
import AllContent from "./Mycontent/AllContent.js";
import ContentTypeList from "./Mycontent/ContentTypeList.js";

import contentStyle from "../../../assets/jss/contentStyle.jsx";

class MyContentPopver extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;

        this.state = {            
            section: 'mycontent',
            contentType: '',
            markupsList: this.props.markupsList,
            markupsCount: this.props.markupsCount,
        };
    }
    componentWillReceiveProps(props){
        this.setState({ 
            markupsList: props.markupsList,
            markupsCount: props.markupsCount,
        });
    }
    openSection(event, section, contentType = null){
        event.preventDefault();
        
        this.setState({
            section: section,
            contentType: contentType,
        })
    }
    getSection(section){
        const { markupsList, markupsCount } = this.state;

        switch(section){
            case "mycontent":   
                return <MyContent 
                    onClose = {this.props.onClose}
                    openSection = {(e,section, contentType) => this.openSection(e,section, contentType)}
                    markupsList={markupsList}
                    markupsCount={markupsCount}
                    store={this.store}
                />;
            case "allcontent":   
                return <AllContent 
                    openSection = {(e,section) => this.openSection(e,section)}
                    markupsList={markupsList}
                    markupsCount={markupsCount}
                    setEditMarkupItem={(markupItem) => this.props.setEditMarkupItem(markupItem)}
                    setDeleteMarkupItem={(markupItem) => this.props.setDeleteMarkupItem(markupItem)}
                    setShowHideMarkupItem={(markupItem) => this.props.setShowHideMarkupItem(markupItem)}
                    setLoadMarkupItem={(markupItem) => this.props.setLoadMarkupItem(markupItem)}
                    store={this.store}
                />;
            case "contentTypeList":
                return <ContentTypeList
                    contentType = {this.state.contentType}
                    openSection = {(e,section) => this.openSection(e,section)}
                    markupsList={markupsList}
                    markupsCount={markupsCount}
                    setDeleteMarkupItem={(markupItem) => this.props.setDeleteMarkupItem(markupItem)}
                    setShowHideMarkupItem={(markupItem) => this.props.setShowHideMarkupItem(markupItem)}
                    setLoadMarkupItem={(markupItem) => this.props.setLoadMarkupItem(markupItem)}
                    store={this.store}
                />;
            default:      
                return <></>
        }
    }
    render() {
        const { id, popupOpen, anchorEl } = this.props;
        const { section } = this.state;

        return (
            <Popover
                id={id}
                open={popupOpen}
                anchorEl={anchorEl}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: 500 }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                { this.getSection(section) }
            </Popover>
        );
    }
}

MyContentPopver.propTypes = {
  classes: PropTypes.object
};

export default withStyles(contentStyle)(MyContentPopver);