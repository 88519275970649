import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Api from "../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import mapboxgl from 'mapbox-gl';
import mapboxDraw from "@mapbox/mapbox-gl-draw";

import mapStyle from "../../assets/jss/mapStyle.jsx";
import DiscardModal from "./Modals/DiscardModal.js";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = 'pk.eyJ1IjoidWh1bnRhcHAiLCJhIjoiY2t2NXZwZHd1OTU4ejJ2bnpjaGRnOXhtdSJ9.k2REVtfUILkeQhnNJpzwTw';
const Map = class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lng: -91.874,
            lat: 42.76,
            zoom: 12,
            lineDesign: this.props.lineDesign,
            shapeDesign: this.props.shapeDesign,
            waypointDesign: this.props.waypointDesign,
            savingInfo: false,
            discardModal: false,
            markupsList: this.props.markupsList,
            deleteMarkupItem: this.props.deleteMarkupItem,
            deleteMarkupModal: false,
            showHideMarkupItem: this.props.showHideMarkupItem,
            loadMarkupItem: this.props.loadMarkupItem,
            markupId: this.props.markupId
        };
        this.mapContainer = React.createRef();
        this.draw = {};
        this.map = {};
        this.canvas = {};
        this.mapData = {};
    }
    componentWillReceiveProps(props){
        if(props.deleteMarkupItem !== null){
            this.deleteMarkupItem()
        }
        if(props.formSubmitStatus === true){
            this.formSubmit()
        }
        if(props.photoModal === true){
            this.handleTool(null, 'waypoint', true);
        }
        if(props.toolsPopoverClosed === true){
            if(this.props.waypointFormData.attachments.length > 0 || props.formButtonStatus === true){
                this.onDiscardModal(true);
            }else{
                this.clearMarkupOnClose();
            }
        }else{
            this.setState({ 
                lineDesign: props.lineDesign,
                shapeDesign: props.shapeDesign,
                waypointDesign: props.waypointDesign,
                markupsList: props.markupsList,
                deleteMarkupItem: props.deleteMarkupItem,
                showHideMarkupItem: props.showHideMarkupItem,
                loadMarkupItem: props.loadMarkupItem,
                markupId: props.markupId
            }, () => {                              
                this.updateColor()

                if(this.state.showHideMarkupItem !== null){
                    this.showHideMarkupItem()
                }
                if(this.state.loadMarkupItem !== null){
                    this.loadMarkupItem()
                }
            });
        }

        if(this.state.markupsList.length !== props.markupsList.length){
            this.drawMarkups(props.markupsList);
        }
    }
    componentDidMount(){
        const map = this.inintMapbox();
        this.map = map;
        const draw = this.initMapboxDraw();
        this.draw = draw;
        const canvas = map.getCanvasContainer();
        this.canvas = canvas;

        map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        map.addControl(draw, 'bottom-right');

        map.on('draw.create', updateArea);
        map.on('draw.delete', updateArea);
        map.on('draw.update', updateArea);

        let that = this;
        function updateArea() {
            const data = draw.getAll();

            if (data.features.length > 0) {
                let featuresLen = data.features.length;
                let mapData = data.features[featuresLen-1];
                that.mapData = mapData;

                that.createMarkupLayer();

                if(that.mapData.geometry.coordinates.length > 0){
                    that.props.setFormButtonStatus(true)
                }
            }
        }
    }
    inintMapbox(){
        const { lng, lat, zoom } = this.state;

        let map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/uhuntapp/ckxpt5wsa1oci15mx9fl3gdun',
            // style: 'mapbox://styles/mapbox/satellite-v9',
            center: [lng, lat],
            zoom: zoom
        });

        return map;
    }
    initMapboxDraw(){
        let draw = new mapboxDraw({
            displayControlsDefault: false,
            // controls: {
            //     polygon: true,
            //     line_string: true,
            //     trash: true
            // },
            defaultMode: 'simple_select',
            styles: this.getStyles()
        });

        return draw;
    }
    handleTool(e, section, photoModal = false){
        if(this.props.popupOpen && photoModal === false){
            return true;
        }

        if(section === 'line'){
            this.draw.changeMode("draw_line_string");
        }else if(section === 'area'){
            this.draw.changeMode("draw_polygon");
        }else if(section === 'waypoint'){
            this.draw.changeMode("draw_point");
        }else if(section === 'photo'){
            this.draw.changeMode("simple_select");
        }

        const data = this.draw.getAll();
        if (data.features.length > 0) {
            let featuresLen = data.features.length;
            let mapData = data.features[featuresLen-1];
            this.mapData = mapData;
        }

        if(photoModal === true){
            this.props.updateSection(section);
        }else{
            this.props.handleClick(e, section);
        }
    }
    updateColor(){
        const { lineDesign, shapeDesign, waypointDesign } = this.state;
        const data = this.draw.getAll();

        if(data.features.length > 0){
            if(this.props.formButtonStatus === true){
                if(this.mapData.geometry.type === 'LineString'){
                    this.map.setPaintProperty(this.mapData.id, 'line-color', lineDesign.color);
                    this.map.setPaintProperty(this.mapData.id, 'line-dasharray', this.getDashArray(lineDesign.style));
                    this.map.setPaintProperty(this.mapData.id, 'line-width', this.getWeight(lineDesign.weight));
                }else if(this.mapData.geometry.type === 'Polygon'){
                    this.map.setPaintProperty(this.mapData.id, 'line-color', shapeDesign.color);
                    this.map.setPaintProperty(this.mapData.id, 'line-dasharray', this.getDashArray(shapeDesign.style));
                    this.map.setPaintProperty(this.mapData.id, 'line-width', this.getWeight(shapeDesign.weight));
                    this.map.setPaintProperty(this.mapData.id+this.mapData.geometry.type, 'fill-color', shapeDesign.fillColor);
                }else if(this.mapData.geometry.type === 'Point'){
                    this.map.setLayoutProperty(this.mapData.id, 'icon-image', 'marker-'+waypointDesign.colorName);
                    this.map.setLayoutProperty(this.mapData.id+this.mapData.geometry.type, 'icon-image', waypointDesign.icon);
                }
            }
        }
    }
    createMarkupLayer(drawMapData = null, draw = false, markup = null){
        const { lineDesign, shapeDesign, waypointDesign } = this.state;
        
        let mapData = this.mapData;
        if(draw === true && drawMapData !== null){
            mapData = drawMapData;
        }

        if (this.map.getSource(mapData.id)) {
            return;
        }
        
        if(!mapData.geometry){
            return;
        }

        let design = {};
        if(draw === true && drawMapData !== null){
            design = drawMapData.properties;
        }else{
            if(mapData.geometry.type === 'LineString'){
                design = lineDesign;
            }else if(mapData.geometry.type === 'Polygon'){
                design = shapeDesign;
            }else if(mapData.geometry.type === 'Point'){
                design = waypointDesign;
            }
        }

        this.map.addSource(mapData.id, {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'geometry': {
                    'type': mapData.geometry.type,
                    'coordinates': mapData.geometry.coordinates
                }
            }
        }); 

        if(mapData.geometry.type === 'LineString' || mapData.geometry.type === 'Polygon'){
            this.map.addLayer({
                'id': mapData.id,
                'type': 'line',
                'source': mapData.id,
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': design.color,
                    'line-dasharray': this.getDashArray(design.style),
                    'line-width': this.getWeight(design.weight)
                }
            });
        }

        if(mapData.geometry.type === 'Polygon'){
            this.map.addLayer({
                'id': mapData.id+mapData.geometry.type,
                'type': 'fill',
                'source': mapData.id,
                'layout': {},
                'paint': {
                    'fill-color': design.fillColor,
                    'fill-opacity': 0.5
                }
            });
        }

        if(mapData.geometry.type === 'Point'){
            this.map.addLayer({
                'id': mapData.id,
                'type': 'symbol',
                'source': mapData.id,
                'layout': {
                    'icon-image': 'marker-'+design.colorName,
                    'icon-size': 0.5,
                    'icon-allow-overlap': true,
                }
            })

            this.map.addLayer({
                'id': mapData.id+mapData.geometry.type,
                'type': 'symbol',
                'source': mapData.id,
                'layout': {
                    'icon-image': design.icon,
                    'icon-offset': [0,-8],
                    'icon-size': 0.8,
                    'icon-allow-overlap': true,
                }
            })

            // attach event on waypoint hover for drag waypoint
            if(draw === false){
                this.attachWaypointDragEvent(mapData);
            }
        }
        
        // attach event on markup for get info on already created markups
        if(draw === true){
            this.attachMarkupInfoEvent(mapData);
        }

        // hide markup which one hidden on page load
        this.showHideMarkupLayer(markup, mapData);
    }
    onMove(e) {
        const coords = e.lngLat;

        this.canvas.style.cursor = 'grabbing';
        this.mapData.geometry.coordinates = [coords.lng, coords.lat];
        
        this.map.getSource(this.mapData.id).setData(this.mapData);
    }
    onUp(e) {
        this.canvas.style.cursor = '';
        
        this.map.off('mousemove', this.onMove(e));
        this.map.off('touchmove', this.onMove(e));
    }
    attachWaypointDragEvent(mapData){
        this.map.on('mouseenter', mapData.id, () => {
            this.canvas.style.cursor = 'move';
        });
        
        this.map.on('mouseleave', mapData.id, () => {
            this.canvas.style.cursor = '';
        });

        this.map.on('mousedown', mapData.id, (e) => {
            e.preventDefault();
            
            this.canvas.style.cursor = 'grab';
            
            this.map.on('mousemove', this.onMove(e));
            this.map.once('mouseup', this.onUp(e));
        });
    
        this.map.on('touchstart', mapData.id, (e) => {
            e.preventDefault();
            
            this.map.on('touchmove', this.onMove(e));
            this.map.once('touchend', this.onUp(e));
        });
    }
    attachMarkupInfoEvent(mapData){
        if(mapData.geometry.type === 'Polygon'){
            this.map.on('click', mapData.id+mapData.geometry.type, (e) => {
                this.getMarkupInfo(mapData.id);
            });
            this.map.on('mouseenter', mapData.id+mapData.geometry.type, () => {
                this.canvas.style.cursor = 'pointer';
            });
            this.map.on('mouseleave', mapData.id+mapData.geometry.type, () => {
                this.canvas.style.cursor = '';
            });
        }else{
            this.map.on('click', mapData.id, (e) => {
                this.getMarkupInfo(mapData.id);
            });
            this.map.on('mouseenter', mapData.id, () => {
                this.canvas.style.cursor = 'pointer';
            });
            this.map.on('mouseleave', mapData.id, () => {
                this.canvas.style.cursor = '';
            });
        }
    }
    getMarkupInfo(mapDataId){
        if(this.props.section === 'line' || this.props.section === 'area' || this.props.section === 'waypoint' || this.props.section === 'track'){
            return true;
        }
        this.setState({
            showError: false
        });

        const source = axios.CancelToken.source();
        Api.getMarkup(mapDataId, source).then((data) => {
            let mapData = data.geo_json;

            let type = mapData.geometry.type;

            this.props.handleClick(null, type, data);
        }).catch(err => {
            
        });
    }
    clearMarkupOnClose(){
        const data = this.draw.getAll();
        if(data.features.length > 0){
            if (this.map.getLayer(this.mapData.id)) {
                this.map.removeLayer(this.mapData.id);
            }
            if(this.mapData.geometry.type === 'Polygon' || this.mapData.geometry.type === 'Point'){
                if (this.map.getLayer(this.mapData.id+this.mapData.geometry.type)) {
                    this.map.removeLayer(this.mapData.id+this.mapData.geometry.type);
                }
            }
            if (this.map.getSource(this.mapData.id)) {
                this.map.removeSource(this.mapData.id);
            }

            this.draw.delete(this.mapData.id).getAll();
        }

        if(this.props.waypointFormData.attachments.length > 0){
            this.deleteWaypointsPhotos(this.props.waypointFormData.attachments)
        }

        this.onDiscardModal(false);
        this.props.onClose();

        this.draw.changeMode("simple_select");
    }
    deleteWaypointsPhotos(attachments){
        var requestData = {};

        let photoIds = [];
        attachments.map((photo, key) => {
            return photoIds.push(photo.id);
        })

        requestData.photoIds = photoIds;

        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.removeWaypointPhotos(requestData, source).then(data => {
            that.setState({
                success: false,
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    formSubmit(){
        const data = this.draw.getAll();
        
        if (data.features.length > 0) {
            if(this.mapData.geometry.coordinates.length > 0){
                this.createMarkup();
            }
        }
    }
    createMarkup(){
        if(!this.mapData){
            return;
        }
        // const isValid = this.validateForm();
        // if(!isValid){
        //     return;
        // }

        this.setState({
            savingInfo: true,
            showError: false
        });

        if(this.mapData.geometry.type === 'LineString'){
            this.mapData.properties = this.state.lineDesign
        }else if(this.mapData.geometry.type === 'Polygon'){
            this.mapData.properties = this.state.shapeDesign
        }else if(this.mapData.geometry.type === 'Point'){
            this.mapData.properties = this.state.waypointDesign
        }

        let values = {
            geo_json: this.mapData,
        }
        if(this.mapData.geometry.type === 'LineString'){
            values['type'] = 'line';
            values['name'] = this.props.lineFormData.title;
            values['notes'] = this.props.lineFormData.notes;
        }else if(this.mapData.geometry.type === 'Polygon'){
            values['type'] = 'shape';
            values['name'] = this.props.shapeFormData.title;
            values['notes'] = this.props.shapeFormData.notes;
        }else if(this.mapData.geometry.type === 'Point'){
            values['type'] = 'waypoint';
            values['name'] = this.props.waypointFormData.title;
            values['notes'] = this.props.waypointFormData.notes;
            values['attachments'] = this.props.waypointFormData.attachments;
        }

        const source = axios.CancelToken.source();
        Api.createMarkup(values, source).then((data) => {
            this.setState({
                savingInfo: false, 
            });
            
            // attach event on markup for get info on create markup
            this.attachMarkupInfoEvent(data.geo_json);

            this.props.onClose();
            this.props.markupsDataUpdate(data);

            this.draw.changeMode("simple_select");
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    drawMarkups(markups){
        markups.map(markup => {
            if(this.state.markupId !== null && this.state.markupId !== markup.id){
                return null;
            }
            
            let mapData = markup.geo_json;

            if(this.state.markupId !== null &&  this.state.markupId === markup.id){
                this.props.setLoadMarkupItem(markup);
                this.getMarkupInfo(mapData.id);
            }

            this.createMarkupLayer(mapData, true, markup);

            return null;
        });
    }
    getWeight(weight){
        switch(weight){
            case 'two':   
                return 2;
            case 'four':   
                return 4;
            case 'six':   
                return 6;
            default:      
                return <></>
        }
    }
    getDashArray(style){
        switch(style){
            case 'solid':
                return [0.1, 0.1];
            case 'dash':
                return [3, 2];
            case 'dot':
                return [0.5, 2];
            default:      
                return <></>
        }
    }
    onDiscardModal(status = false, cancel = false){
        this.setState({
            discardModal: status,
        });

        if(cancel === true){
            this.props.changeToolsPopoverStatus();
        }
    }
    deleteMarkupItem(){
        this.setState({
            deleteMarkupModal: true
        })
    }
    onDeleteMarkupModal(status = false){
        const { deleteMarkupItem } = this.state;
        if(status === true){
            let values = {
                id: deleteMarkupItem.id
            }

            const source = axios.CancelToken.source();
            Api.deleteMarkup(values, source).then((data) => {
                if(data.status === true){
                    let mapData = deleteMarkupItem.geo_json;
                    
                    if (this.map.getLayer(mapData.id)) {
                        this.map.removeLayer(mapData.id);
                    }
                    if(mapData.geometry.type === 'Polygon' || mapData.geometry.type === 'Point'){
                        if (this.map.getLayer(mapData.id+mapData.geometry.type)) {
                            this.map.removeLayer(mapData.id+mapData.geometry.type);
                        }
                    }
                    if (this.map.getSource(mapData.id)) {
                        this.map.removeSource(mapData.id);
                    }

                    this.draw.delete(mapData.id).getAll();

                    this.props.removeMarkupContent(deleteMarkupItem)
                }
            }).catch(err => {
                if(typeof(err) === "object"){
                    let errors = "";
                    if(err.hasOwnProperty("message")){
                        errors = err.message;
                    }
                    if(err.hasOwnProperty("errors")){
                        errors = err.errors;
                    }
                    this.setState({
                        savingInfo: false, 
                        showError: true, 
                        errorMessage: errors
                    });
                }
            });
        }

        this.setState({
            deleteMarkupModal: false
        }, () => {                              
            this.props.setDeleteMarkupItem(null);
        })
    }
    showHideMarkupItem(){
        const { showHideMarkupItem } = this.state;

        let values = {
            id: showHideMarkupItem.id
        }

        const source = axios.CancelToken.source();
        Api.showHideMarkup(values, source).then((data) => {
            if(data.status === true){
                let mapData = showHideMarkupItem.geo_json;
                
                this.showHideMarkupLayer(data.markup, mapData);
                
                this.props.updateMarkupContent(data.markup);
            }
        }).catch(err => {
            
        });

        this.props.setShowHideMarkupItem(null);
    }
    showHideMarkupLayer(markup, mapData){
        if(markup === null || mapData === null){
            return;
        }

        let visibility = "none";
        if(markup.status === true){
            visibility = "visible"
        }

        if(mapData.geometry.type === 'LineString'){
            this.map.setLayoutProperty(mapData.id, 'visibility', visibility);
        }else if(mapData.geometry.type === 'Polygon'){
            this.map.setLayoutProperty(mapData.id, 'visibility', visibility);
            this.map.setLayoutProperty(mapData.id+mapData.geometry.type, 'visibility', visibility);
        }else if(mapData.geometry.type === 'Point'){
            this.map.setLayoutProperty(mapData.id, 'visibility', visibility);
            this.map.setLayoutProperty(mapData.id+mapData.geometry.type, 'visibility', visibility);
        }
    }
    loadMarkupItem(){
        const { loadMarkupItem, zoom } = this.state;
        let coordinates = loadMarkupItem.geo_json.geometry.coordinates;
        
        if(coordinates.length <= 0){
            this.props.setLoadMarkupItem(null);
            return;
        }

        if(loadMarkupItem.type === "line" || loadMarkupItem.type === "track"){
            if(coordinates.length > 0){
                coordinates = loadMarkupItem.geo_json.geometry.coordinates[0];
            }
        }else if(loadMarkupItem.type === "shape"){
            if(coordinates.length > 0 && coordinates[0].length > 0){
                coordinates = loadMarkupItem.geo_json.geometry.coordinates[0][0];
            }
        }

        this.map.flyTo({
            center: coordinates,
            zoom: zoom,
            essential: true,
        });

        this.props.setLoadMarkupItem(null);
    }
    getStyles(){
        return [
            {
                'id': 'gl-draw-polygon-stroke-inactive',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#fff',
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-active',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#fff',
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-line-inactive',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'LineString'],
                    ['!=', 'mode', 'static']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': this.state.lineDesign.color,
                    'line-dasharray': this.getDashArray(this.state.lineDesign.style),
                    'line-width': this.getWeight(this.state.lineDesign.weight),
                }
            },
            {
                'id': 'gl-draw-line-active',
                'type': 'line',
                'filter': ['all', ['==', '$type', 'LineString'],
                    ['==', 'active', 'true']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': this.state.lineDesign.color,
                    'line-dasharray': this.getDashArray(this.state.lineDesign.style),
                    'line-width': this.getWeight(this.state.lineDesign.weight),
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-static',
                'type': 'line',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'Polygon']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#fff',
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-line-static',
                'type': 'line',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'LineString']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': this.state.lineDesign.color,
                    'line-dasharray': this.getDashArray(this.state.lineDesign.style),
                    'line-width': this.getWeight(this.state.lineDesign.weight),
                }
            },
            {
                'id': 'gl-draw-polygon-fill-inactive',
                'type': 'fill',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'fill-color': this.state.shapeDesign.fillColor,
                    'fill-opacity': 0.5
                }
            },
            {
                'id': 'gl-draw-polygon-fill-active',
                'type': 'fill',
                'filter': ['all', ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                'paint': {
                    'fill-color': this.state.shapeDesign.fillColor,
                    'fill-opacity': 0.5
                }
            },
            {
                'id': 'gl-draw-polygon-fill-static',
                'type': 'fill',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'Polygon']
                ],
                'paint': {
                    'fill-color': this.state.shapeDesign.fillColor,
                    'fill-opacity': 0.5
                }
            },
            {
                'id': 'gl-draw-polygon-midpoint',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['==', 'meta', 'midpoint']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-color': '#fff'
                }
            },
            {
                'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-color': '#fff'
                }
            },
            {
                'id': 'gl-draw-polygon-and-line-vertex-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-color': '#fff'
                }
            }
        ]
    }
    render() {
        const { classes, popupOpen } = this.props;
        const { discardModal, deleteMarkupModal } = this.state;

        return (
            <>
                <div className={classes.mapSection}>     
                    <div className="map" id="map" ref={this.mapContainer}></div>
                    <div className="controls-outside-right controls-column">
                        <div id="toolbox" className="huntTheme">
                            <h3 >Tools</h3>
                            <ul id="map-tools">
                                <li id="add-line-string" className={"tool-item "+(popupOpen ? 'disable': '')} onClick={(e) => this.handleTool(e, 'line')} variant="contained" aria-describedby={this.props.id} >
                                    <div className="tool-icon" title="Draw Line / Measure Distance">
                                        <span className="x-icon">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.2633 3.35138L14.6915 4.60704C14.5949 4.81934 14.3443 4.91306 14.131 4.81647L10.2818 3.06448C10.0695 2.96693 9.97579 2.71637 10.0724 2.50407L10.6443 1.24841C10.7409 1.03515 10.9914 0.941425 11.2047 1.03801L15.0539 2.79097C15.2662 2.88756 15.3599 3.13907 15.2633 3.35138Z" fill="inherit"></path>
                                                <path d="M0.480078 14.5H5.93211C6.19702 14.5 6.41219 14.2848 6.41219 14.0199C6.41219 13.755 6.19702 13.5398 5.93211 13.5398H0.480078C0.215174 13.5398 0 13.755 0 14.0199C0 14.2848 0.215174 14.5 0.480078 14.5Z" fill="inherit"></path>
                                                <path d="M11.3858 11.8664L13.9899 6.14655C14.0865 5.93424 13.9928 5.68273 13.7805 5.58614L9.93125 3.83318C9.71895 3.73659 9.46743 3.83032 9.37084 4.04358L6.76676 9.76339C6.67017 9.97569 6.76389 10.2262 6.97619 10.3238L10.8254 12.0758C11.0387 12.1724 11.2892 12.0787 11.3858 11.8664Z" fill="inherit"></path>
                                                <path d="M7.13674 14.083L6.60694 11.4531C6.57251 11.2838 6.74465 11.1471 6.90149 11.2188L10.1989 12.7203C10.3567 12.792 10.3663 13.011 10.2161 13.0961L7.88555 14.4235C7.58431 14.5947 7.20464 14.4216 7.13674 14.083Z" fill="inherit"></path>
                                            </svg>
                                        </span>
                                    </div> 
                                    <div className="tool-label">Line Distance</div>
                                </li>
                                <li id="add-shape" className={"tool-item "+(popupOpen ? 'disable': '')} onClick={(e) => this.handleTool(e, 'area')} variant="contained" aria-describedby={this.props.id} >
                                    <div className="tool-icon" title="Draw Shape / Measure Area">
                                        <span className="x-icon">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.0864 1.53626L15.7633 3.21022C15.9663 3.30222 16.0563 3.54221 15.9633 3.74521L15.4173 4.94418C15.3253 5.14818 15.0853 5.23718 14.8823 5.14518L11.2054 3.47121C11.0024 3.37822 10.9134 3.13922 11.0054 2.93623L11.5514 1.73625C11.6444 1.53326 11.8834 1.44426 12.0864 1.53626Z" fill="inherit"></path>
                                                <path d="M10.8711 4.20621L14.548 5.88017C14.751 5.97317 14.841 6.21217 14.748 6.41516L12.2611 11.879C12.1681 12.082 11.9291 12.171 11.7261 12.079L8.04915 10.4051C7.84616 10.3121 7.75616 10.0731 7.84916 9.87009L10.3361 4.4062C10.4291 4.20321 10.6681 4.11421 10.8711 4.20621Z" fill="inherit"></path>
                                                <path d="M11.1272 12.6944L7.97723 11.2604C7.82723 11.1924 7.66324 11.3224 7.69624 11.4844L8.20123 13.9964C8.26722 14.3194 8.63022 14.4854 8.91721 14.3214L11.1432 13.0534C11.2862 12.9724 11.2772 12.7624 11.1272 12.6944Z" fill="inherit"></path>
                                                <path d="M1.56997 13.8082H6.68486C6.96185 13.8082 7.18684 14.0322 7.18684 14.3102C7.18684 14.5872 6.96185 14.8122 6.68486 14.8122H1.56997C0.704985 14.8122 0 14.1072 0 13.2422V10.4633C0 9.91729 0.290994 9.4023 0.757984 9.12131L3.62192 7.39135C4.02691 7.14735 4.5129 7.09735 4.95789 7.25535C5.40188 7.41235 5.74888 7.75634 5.90887 8.19933L6.40086 9.5583C6.49486 9.81829 6.35986 10.1063 6.09887 10.2003C5.83887 10.2953 5.55188 10.1603 5.45688 9.89929L4.96589 8.54032C4.90789 8.38233 4.7829 8.25733 4.6229 8.20033C4.4609 8.14433 4.28591 8.16133 4.14091 8.24933L1.27697 9.97929C1.10798 10.0813 1.00398 10.2663 1.00398 10.4633V13.2422C1.00398 13.5542 1.25797 13.8082 1.56997 13.8082Z" fill="inherit"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="tool-label">Area Shape</div>
                                </li> 
                                <li id="add-point" className={"tool-item "+(popupOpen ? 'disable': '')} onClick={(e) => this.handleTool(e, 'waypoint')} variant="contained" aria-describedby={this.props.id} >
                                    <div className="tool-icon" title="Add Waypoint">
                                        <span className="x-icon">
                                            <svg width="14" height="18" viewBox="0 0 14 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 6.74817C0.5 3.30073 3.41257 0.5 7.00035 0.5C10.5917 0.5 13.5002 3.30073 13.5 6.74817C13.5 8.18486 12.9558 9.47615 12.1547 10.5577C9.57751 14.0289 8.53075 15.7644 8.00737 16.6322C7.48399 17.5 7.48399 17.5 7.00035 17.5C6.51671 17.5 6.51671 17.5 5.9934 16.6323C5.47009 15.7646 4.42346 14.0293 1.84657 10.5585C1.04493 9.47667 0.5 8.18856 0.5 6.74817ZM7.00044 10.6498C9.24324 10.6498 11.058 8.90727 11.058 6.74845C11.058 4.59522 9.2434 2.84779 7.00044 2.84779C4.76084 2.84779 2.94238 4.59522 2.94238 6.74845C2.94238 8.90731 4.76084 10.6498 7.00044 10.6498Z"></path>
                                            </svg>
                                        </span>
                                    </div> 
                                    <div className="tool-label">Add Waypoint</div>
                                </li> 
                                <li id="add-photo" className={"tool-item "+(popupOpen ? 'disable': '')} onClick={(e) => this.handleTool(e, 'photo')} variant="contained" aria-describedby={this.props.id} >
                                    <div className="tool-icon" title="Add Photo Waypoint">
                                        <span className="x-icon">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.6667 6.55167C12.6667 6.82767 12.8907 7.05167 13.1667 7.05167C13.4427 7.05167 13.6667 6.82767 13.6667 6.55167C13.6667 6.27567 13.4427 6.05167 13.1667 6.05167C12.8907 6.05167 12.6667 6.27567 12.6667 6.55167ZM4.31268 8.23933C4.31268 10.2723 5.96702 11.9267 8.00002 11.9267C10.0334 11.9267 11.6877 10.2723 11.6877 8.23933C11.6877 6.206 10.0334 4.55167 8.00002 4.55167C5.96702 4.55167 4.31268 6.206 4.31268 8.23933ZM12.9133 3.989H14.5C15.3287 3.989 16 4.66067 16 5.48933V12.5977C16 12.7277 15.895 12.833 15.765 12.833H0.235333C0.105333 12.833 0 12.7277 0 12.5977V5.48933C0 4.66067 0.671667 3.989 1.5 3.989H3.181C3.412 3.989 3.61267 3.831 3.66667 3.60633L4.01667 2.14933C4.18033 1.47267 4.78 1 5.47567 1H10.5557C11.2243 1 11.818 1.449 11.9993 2.09267L12.4317 3.625C12.493 3.83967 12.6907 3.989 12.9133 3.989ZM8.00017 5.55167C6.51817 5.55167 5.3125 6.75767 5.3125 8.23933C5.3125 9.72067 6.51817 10.9267 8.00017 10.9267C9.48217 10.9267 10.6878 9.72067 10.6878 8.23933C10.6878 6.75767 9.48217 5.55167 8.00017 5.55167ZM0.381542 13.6565C0.205876 13.6565 0.0902089 13.8431 0.170542 13.9995C0.419542 14.4818 0.920876 14.8128 1.50021 14.8128H14.5002C15.0795 14.8128 15.5809 14.4818 15.8299 13.9995C15.9105 13.8431 15.7945 13.6565 15.6189 13.6565H0.381542Z" fill="inherit"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="tool-label">Add Photo</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {
                    discardModal ?
                        <DiscardModal 
                            open={discardModal} 
                            onClose={() => this.onDiscardModal(false, true)} 
                            onDiscard={() => this.clearMarkupOnClose()}
                            title="Are you sure you want to discard this?"
                            buttonText="Discard"
                        />
                    :
                        <></>
                }
                {
                    deleteMarkupModal ? 
                        <DiscardModal 
                            open={deleteMarkupModal} 
                            onClose={() => this.onDeleteMarkupModal(false)} 
                            onDiscard={() => this.onDeleteMarkupModal(true)}
                            title="Are you sure you want to delete this?"
                            buttonText="Delete"
                        />
                    :
                        <></>
                }
            </>
        );
    }
};

Map.propTypes = {
  classes: PropTypes.object
};
export default withStyles(mapStyle)(Map);
