
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../CustomButtons/Button.jsx";
import myaccountStyle from "../../../../assets/jss/myaccountStyle.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import PasswordMatch from "../../../../assets/js/utils/PasswordMatch";
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import LoaderComponent from '../../../components/Loader'

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.history = this.props.history;
        this.store = this.props.store;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            values: {
                ...values,
                password: '',
                old_password: '',
                password_confirmation: ''
            },
            editInfo: false,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            showPasswordErrors: false,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            validation: {
                old_password: '',
                password: '',
                password_confirmation: '',
                isValid: false
            },
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
    }
    saveUserInfo(e){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            editInfo: false, 
            savingInfo: true,
            showError: false
        });
        this.updatePassword(e);
    }
    validateForm(){
        const { values } = this.state;
        let validation = {
            old_password: "success",
            password: "success",
            password_confirmation: "success",
            isValid: true
        };
        
        const that = this;
        const check = PasswordMatch.check(values.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
                showPasswordErrors: true,
                requireLowerletter: requireLowerletter,
                requireUpperletter: requireUpperletter,
                requireNumber: requireNumber,
                requireSymbol: requireSymbol,
                requireLength: requireLength
            });
        });
        if(values.old_password.length <= 0){
            validation.old_password = "error";
            validation.isValid = false;
        }
        if(values.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(values.password_confirmation.length <= 0 || values.password !== values.password_confirmation){
            validation.password_confirmation = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    updatePassword(e){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);

        const source = axios.CancelToken.source();
        Api.updatePassword(user.id, values, source).then((data) => {
            this.setState({
                savingInfo: false, 
            });
            
            this.props.openSection(e,'myaccount');
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    handleValueChange(event, name) {
        this.setState({
            values: {
                ...this.state.values, 
                [name]: event.target.value
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    render() {
        const { classes } = this.props;
        const { savingInfo, showError, validation, values, showPasswordErrors, requireLength, requireLowerletter, requireNumber, requireSymbol, requireUpperletter } = this.state;
        
        return (
            <div className={classes.popoverContent}>
                <header>
                    <div className="close">
                        <IconButton title="Close card" onClick={(e) => this.props.openSection(e,'myaccount')}>
                            <span className="x-icon">
                                <svg width="25" height="30" viewBox="0 0 30 30" fill="#333" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.7677 7.62548C21.3772 7.23496 20.744 7.23496 20.3535 7.62548L14.9997 12.9792L9.64623 7.62577C9.25571 7.23524 8.62254 7.23524 8.23202 7.62577L7.62593 8.23186C7.23541 8.62238 7.23541 9.25555 7.62593 9.64607L12.9794 14.9996L7.62556 20.3534C7.23503 20.7439 7.23503 21.3771 7.62556 21.7676L8.23165 22.3737C8.62217 22.7642 9.25534 22.7642 9.64586 22.3737L14.9997 17.0199L20.3539 22.374C20.7444 22.7645 21.3775 22.7645 21.7681 22.374L22.3742 21.7679C22.7647 21.3774 22.7647 20.7442 22.3742 20.3537L17.02 14.9996L22.3738 9.64579C22.7643 9.25526 22.7643 8.6221 22.3738 8.23157L21.7677 7.62548Z" fill="#333"></path>
                                </svg>
                            </span>
                        </IconButton>
                    </div>
                    <div className="titles">
                        <h3 className="title3 title">Change Password</h3>
                    </div>
                </header>
                <div className="change-password">
                    <div className="form-fields">
                        {
                            showError ?
                                this.renderErrorMessages()
                            :
                            <></>
                        }
                        <CustomInput
                            success={validation.old_password === "success"}
                            error={validation.old_password === "error"}
                            id="outlined-old_password"
                            labelText="Old Password"                                    
                            inputProps={{
                                value: values.old_password,
                                onChange: (e) => this.handleValueChange(e, 'old_password'),
                                name: "old_password",
                                type: "password",
                            }}                                    
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                        <CustomInput
                            success={validation.password === "success"}
                            error={validation.password === "error"}
                            id="outlined-password"
                            labelText="Password"                                    
                            inputProps={{
                                value: values.password,
                                onChange: (e) => this.handleValueChange(e, 'password'),
                                name: "password",
                                type: "password",
                            }}                                    
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                        {
                            showPasswordErrors ?
                                <div className={"password-errors"}>
                                    <div>
                                        <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                        </div>
                                        <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                        </div>
                                        <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-symbols">Password must contain a number</span>
                                        </div>
                                        <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                        </div>
                                        <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                        </div>
                                    </div>
                                </div>
                            :
                            <></>
                        }
                        <CustomInput
                            success={validation.password_confirmation === "success"}
                            error={validation.password_confirmation === "error"}
                            id="outlined-password_confirmation"
                            labelText="Confirm Password"                                    
                            inputProps={{
                                value: values.password_confirmation,
                                onChange: (e) => this.handleValueChange(e, 'password_confirmation'),
                                name: "password_confirmation",
                                type: "password",
                            }}                                    
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </div>
                    <div className="footer-actions">
                        {
                            savingInfo ?
                               <Button fullWidth color="danger">
                                    <LoaderComponent saving={true} align="center" color="white"/>
                                </Button>
                            :
                                <Button fullWidth color="danger" onClick={(e) => this.saveUserInfo(e)}>
                                    <span>Update</span>
                                </Button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

ChangePassword.propTypes = {
  classes: PropTypes.object
};

export default withStyles(myaccountStyle)(ChangePassword);
