
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Popover from '@material-ui/core/Popover';
import LineArea from "./MarkupDetail/LineArea";
import Point from "./MarkupDetail/Point";
import markupDetail from "../../../assets/jss/markupDetail.jsx";

class MarkupDetailPopover extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;

        this.state = {            
            section: 'mycontent',
            contentType: '',
        };
    }
    openSection(event, section, contentType = null){
        event.preventDefault();
        
        this.setState({
            section: section,
            contentType: contentType
        })
    }
    getSection(){
        const { section, popupMarkupItem } = this.props;

        switch(section){
            case "Polygon":
            case "LineString":
                return <LineArea 
                    onClose = {this.props.onClose}
                    popupMarkupItem={popupMarkupItem}
                    setDeleteMarkupItem={(markupItem) => this.props.setDeleteMarkupItem(markupItem)}
                    setShowHideMarkupItem={(markupItem) => this.props.setShowHideMarkupItem(markupItem)}
                    store={this.store}
                />
            case "Point":
                return <Point 
                    onClose = {this.props.onClose}
                    popupMarkupItem={popupMarkupItem}
                    setDeleteMarkupItem={(markupItem) => this.props.setDeleteMarkupItem(markupItem)}
                    setShowHideMarkupItem={(markupItem) => this.props.setShowHideMarkupItem(markupItem)}
                    store={this.store}
                />
            default:      
                return <></>
        }
    }
    render() {
        const { id, popupOpen, anchorEl } = this.props;
        const { section } = this.state;

        return (
            <Popover
                id={id}
                open={popupOpen}
                anchorEl={anchorEl}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: 500 }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                { this.getSection(section) }
            </Popover>
        );
    }
}

MarkupDetailPopover.propTypes = {
    classes: PropTypes.object
};

export default withStyles(markupDetail)(MarkupDetailPopover);
