import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import { Slide, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@material-ui/core";
import LoaderComponent from "../Loader";
import CustomInput from "../CustomInput/CustomInput.jsx";

import newFolderModalStyle from "../../../assets/jss/modals/newFolderModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NewFolderModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            title: ''
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    render() {
        const { classes, saving } = this.props;
        const { title } = this.state;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>New Folder</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                >
                    {
                        saving ?
                            <LoaderComponent align="center" color="success" />
                        :
                            <>
                                <DialogContentText className={classes.textCenter}>
                                    Enter a name for this folder
                                </DialogContentText>
                                <CustomInput
                                    id="input-titleText"
                                    labelText="Title"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e,'title'),
                                        value: title,
                                        name: "title",
                                        type: "text",
                                    }}
                                    color="info"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customFormControlClasses
                                    }}
                                />
                            </>
                    }
                </DialogContent>
                    
                
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving === false ?
                            <>
                                <Button onClick={() => this.props.onClose()} color="white">
                                    Cancel
                                </Button>
                                <Button className={classes.rightButton} onClick={() => this.props.onSuccess(false)} color="danger">
                                    Save
                                </Button>
                            </>
                        :
                        <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

NewFolderModal.defaultProps = {
    open: false,
    saving: false
}
NewFolderModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    saving: PropTypes.bool
}
export default withStyles(newFolderModalStyle)(NewFolderModal);