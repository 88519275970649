import React from 'react';
import { Route, Switch } from "react-router-dom";
import Home from '../pages/Home';
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
// import NotFound from './NotFound';


const RouterComponent = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
    }

    render() {
        const { history } = this.props;
        return (
            <div id="global_content">
                <Switch>
                    <Route exact path="/" component={() => <Login store={this.store} history={history} />} />
                    <Route exact path="/login" component={() => <Login store={this.store} history={history} />} />
                    <Route exact path="/auth/forgot" component={() => <Login store={this.store} history={history} forgot={true} />} />
                    <Route exact path="/signup" component={() => <Signup store={this.store} history={history} />} />
                    <Route exact path="/home" component={(match) => <Home store={this.store} history={history} match={match} />} />
                    <Route exact path="/home/:id" component={(match) => <Home store={this.store} history={history} match={match} />} />
                    {/* <Route path="*" exact={true} component={NotFound} /> */}
                </Switch>                
            </div>
        )
    }
}

export default RouterComponent