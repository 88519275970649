
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../CustomButtons/Button.jsx";
import myaccountStyle from "../../../../assets/jss/myaccountStyle.jsx";
import profilePicture from "../../../../assets/img/profile-picture.png";
import { Link } from "react-router-dom";
import AuthApi from '../../../../assets/js/utils/Auth';
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import LoaderComponent from '../../../components/Loader'

class MyAccount extends React.Component {
    constructor(props) {
        super(props);

        this.history = this.props.history;
        this.store = this.props.store;

        this.state = {
            logout: false
        }
    }
    getUserTitle(user){
        let title = "";
        if(user.hasOwnProperty("first_name")){
            title += user.first_name;
        }
        if(user.hasOwnProperty("last_name")){
            title += " "+user.last_name;
        }
        return title;
    }
    logout(){
        this.setState({
            logout: true
        })
        const source = axios.CancelToken.source();
        const requestData = {}
        Api.revokeAccessToken(requestData, source).then(data => {
            AuthApi.logout();
            window.location.href = "/";
        }).catch(err => {
            this.setState({
                logout: false
            })  
        });
    }
    render() {
        const { classes } = this.props;
        const { logout } = this.state;
        const { user } = this.store.getState();
        
        return (
            <div className={classes.popoverContent}>
                <header>
                    <div className="close">
                        <IconButton title="Close card" onClick={this.props.onClose}>
                            <span className="x-icon">
                                <svg width="25" height="30" viewBox="0 0 30 30" fill="#333" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.7677 7.62548C21.3772 7.23496 20.744 7.23496 20.3535 7.62548L14.9997 12.9792L9.64623 7.62577C9.25571 7.23524 8.62254 7.23524 8.23202 7.62577L7.62593 8.23186C7.23541 8.62238 7.23541 9.25555 7.62593 9.64607L12.9794 14.9996L7.62556 20.3534C7.23503 20.7439 7.23503 21.3771 7.62556 21.7676L8.23165 22.3737C8.62217 22.7642 9.25534 22.7642 9.64586 22.3737L14.9997 17.0199L20.3539 22.374C20.7444 22.7645 21.3775 22.7645 21.7681 22.374L22.3742 21.7679C22.7647 21.3774 22.7647 20.7442 22.3742 20.3537L17.02 14.9996L22.3738 9.64579C22.7643 9.25526 22.7643 8.6221 22.3738 8.23157L21.7677 7.62548Z" fill="#333"></path>
                                </svg>
                            </span>
                        </IconButton>
                    </div>
                    <div className="titles">
                        <h3 className="title3 title">My Account</h3>
                    </div>
                </header>
                <div className="myaccount-content">
                    <div className="user-icon">
                        {
                            user.profile_photo ?
                                <img className={"avatar-img"} src={user.profile_photo} alt={"avatar"} />
                            :
                                <img alt="avatar" src={profilePicture} className="avatar-img" />
                        }
                    </div>
                    <div className="acount-overview">
                        <div className="subtitle1">{ this.getUserTitle(user) }</div>
                        <div className="email body1">{ user.email }</div>
                        <Button type="button" size="sm" color="danger" onClick={(e) => this.props.openSection(e,'editProfile')} >
                            <span>Edit Profile</span>
                        </Button>
                    </div>
                    <div className="manage-account">
                        <div className="section-header metadata3">Account Info</div>
                        <hr/>
                        <Link to="/" onClick={(e) => this.props.openSection(e,'changePassword')} className="x-internal-link huntTheme">
                            Change Password
                        </Link>
                    </div>
                    <div className="footer-actions">
                            {
                                logout === true ?
                                    <Button fullWidth color="danger">
                                        <LoaderComponent saving={true} align="center" color="white"/>
                                    </Button>
                                :
                                    <Button type="button" fullWidth color="danger" onClick={() => this.logout()}>
                                        <span>Logout</span>
                                    </Button>
                            }
                    </div>
                </div>
            </div>
        );
    }
}

MyAccount.propTypes = {
  classes: PropTypes.object
};

export default withStyles(myaccountStyle)(MyAccount);
