
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Popover from '@material-ui/core/Popover';
import MyAccount from "./MyAccount/MyAccount.js";
import ChangePassword from "./MyAccount/ChangePassword.js";
import EditProfile from "./MyAccount/EditProfile.js";

import contentStyle from "../../../assets/jss/contentStyle.jsx";

class MyAccountPopover extends React.Component {
    constructor(props) {
        super(props);

        this.history = this.props.history;
        this.store = this.props.store;

        this.state = {            
            section: 'myaccount',
            contentType: '',
        };
    }
    openSection(event, section, contentType = null){
        event.preventDefault();
        
        this.setState({
            section: section,
            contentType: contentType
        })
    }
    getSection(section){
        switch(section){
            case "myaccount":   
                return <MyAccount 
                    onClose = {this.props.onClose}
                    openSection = {(e,section, contentType) => this.openSection(e,section, contentType)}
                    store={this.store}
                    history={this.history}
                />;
            case "changePassword":   
                return <ChangePassword 
                    openSection = {(e,section) => this.openSection(e,section)}
                    store={this.store}
                    history={this.history}
                />;
            case "editProfile":   
                return <EditProfile 
                    openSection = {(e,section) => this.openSection(e,section)}
                    store={this.store}
                    history={this.history}
                />;
            default:      
                return <></>
        }
    }
    render() {
        const { id, popupOpen, anchorEl } = this.props;
        const { section } = this.state;

        return (
            <Popover
                id={id}
                open={popupOpen}
                anchorEl={anchorEl}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: 500 }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                { this.getSection(section) }
            </Popover>
        );
    }
}

MyAccountPopover.propTypes = {
  classes: PropTypes.object
};

export default withStyles(contentStyle)(MyAccountPopover);
