
import {
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  coloredShadow,
  whiteColor,
  main,
  dangerColor,
} from "./material-kit-pro-react.jsx";
import customSelectStyle from "./material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "./material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const toolsStyle = theme => ({
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    container,
    mlAuto,
    mrAuto,
    description,
    coloredShadow,
    cardTitle,
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    popoverContent: {
        display: "flex",
        flexDirection: "column",
        background: whiteColor,
        borderRadius: "10px",
        zIndex: "5",
        height: "auto",
        maxHeight: "calc(100vh - 40px)",
        overflowY: "auto",
        boxShadow: ",0 0.25rem 0.3125rem 0 rgb(0 0 0 / 20%), 0 0.4375rem 0.625rem 0 rgb(0 0 0 / 14%), 0 0.125rem 1rem 0 rgb(0 0 0 / 12%)",
        padding: "0",
        width: "375px",
        [theme.breakpoints.down("xs")]: {
            width: "320px"
        },
        "& header": {
            float: "left",
            display: "block",
            marginRight: "0px",
            width: "100%",
            background: "#fff",
            borderRadius: "5px 5px 0 0",
            minHeight: "0",
            "& .close": {
                position: "absolute",
                top: "0",
                right: "0",
                "& button, button:hover": {
                    paddingTop: "5px",
                    background: "transparent"
                }
            },
        },
        "& .popover-content": {
            borderRadius: "0px 0px 5px 5px",
            padding: "20px",
            maxHeight: "100vh",
            overflowY: "auto",
            width: "100%",
            paddingTop: "0",
            paddingBottom: "90px",
            marginTop: "30px",
            "& .edit-markup-content": {
                float: 'left',
                display: "block",
                marginRight: "0px",
                width: "100%",
                "& .MuiFormControl-fullWidth": {
                    marginBottom: "2rem"
                },
                "& .edit-style-section": {
                    width: "48%",
                    marginRight: "4%",
                    "& li": {
                        cursor: "pointer",
                        float: "left",
                    },
                    "& .styles": {
                        "& .style": {
                            width: "33%",
                            height: "14px",
                            marginTop: "5px",
                            border: "5px solid #fff",
                            borderRadius: "4px",
                            "&.selected": {
                                border: "5px solid #e2e2e2",
                            },
                            "& .inner-solid": {
                                background: "#333",
                                height: "4px",
                            },
                            "& .inner-dash": {
                                borderTop: "4px dashed #333",
                                height: "4px",
                            },
                            "& .inner-dot": {
                                borderTop: "4px dotted #333",
                                height: "4px",
                            }
                        }
                    }
                },
                "& .edit-weight-section": {
                    width: "48%",
                    marginRight: "0px",
                    "& li": {
                        cursor: "pointer",
                        float: "left",
                    },
                    "& .weights": {
                        "& .weight": {
                            background: "#333",
                            width: "33%",
                            marginTop: "5px",
                            border: "5px solid #fff",
                            borderRadius: "4px",
                            "&.selected": {
                                border: "5px solid #e2e2e2",
                            },
                            "&.two": {
                                height: "12px",
                            },
                            "&.four": {
                                height: "14px",
                            },
                            "&.six": {
                                height: "16px",
                            }
                        }
                    }
                },
                "& .edit-actions": {
                    float: "left",
                    display: "block",
                    margin: "1em -20px -20px",
                    padding: "20px 20px",
                    zIndex: "1000",
                    position: "absolute",
                    bottom: "20px",
                    width: "100%",
                    background: whiteColor,
                    marginRight: "0px"
                },
                "& form": {
                    float: "left",
                    display: "block",
                    marginRight: "0",
                    width: "100%",
                }
            },
            "& .edit-draw-type-section": {
                float: "left",
                marginRight: "2.3576520234%",
                width: "100%",
                display: "none !Important",
                "& .types": {
                    width: "100%",
                    backgroundColor: "#f5f5f5",
                    padding: "4px",
                    display: "flex",
                    borderRadius: "5px",
                    marginTop: "10px",
                    "& button": {
                        width: "50%",
                        padding: "10px 10px",
                        "& svg": {
                            width: "14px"
                        }
                    }
                }
            },
            "& .markup-card-section": {
                marginBottom: "2rem",
                padding: "0px",
                borderBottom: "none",
                float: "left",
                width: "100%",
                "& ul": {
                    margin: "0px",
                    padding: "0px",
                    listStyleType: "none",
                },
                "& .markup-card-section-header": {
                    "& label": {
                        fontSize: "1.125rem",
                        lineHeight: "1.3333333333",
                        color: "#333",
                        borderBottom: "1px solid #e2e2e2",
                        paddingBottom: "5px",
                        display: "block",
                        fontWeight: "500"
                    }
                },
                "&.photo-area-section": {
                    "& .markup-card-section-header": {
                        borderBottom: "1px solid #e2e2e2",
                        paddingBottom: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        "& label": {
                            borderBottom: "none",
                            paddingBottom: "0px",
                        }
                    }
                }
            },
            "& .edit-color-section": {
                "& .colors": {
                    marginLeft: "-6px",
                    "& .color": {
                        cursor: "pointer",
                        float: "left",
                        position: "relative",
                        borderRadius: "50%",
                        border: "1px solid #e2e2e2",
                        boxSizing: "border-box",
                        margin: "7px 8px",
                        width: "48px",
                        height: "48px",
                        [theme.breakpoints.down("xs")]: {
                            width: "40px",
                            height: "40px",
                        },
                        "&.selected": {
                            borderRadius: "50%",
                            border: "3px solid #bdbdbd"
                        }
                    }
                }
            },
            "& .edit-icon-section": {
                "& .icons": {
                    marginLeft: "-6px",
                    "& .icon": {
                        cursor: "pointer",
                        float: "left",
                        position: "relative",
                        borderRadius: "50%",
                        border: "1px solid #e2e2e2",
                        boxSizing: "border-box",
                        margin: "7px 8px",
                        width: "48px",
                        height: "48px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        [theme.breakpoints.down("xs")]: {
                            width: "40px",
                            height: "40px",
                        },
                        "&.selected": {
                            borderRadius: "50%",
                            border: "1px solid #f30",
                            backgroundColor: "#f30",
                            "& svg": {
                                fill: "#fff"
                            }
                        }
                    }
                }
            }
        },
        "& .content": {
            padding: "15px 20px 20px",
            overflowY: "visible",
            overflowX: "hidden",
            width: "100%",
            "& .subsection-header-collections": {
                display: "flex",
                flexDirection: "row",
                paddingBottom: "10px"
            },
            "& .collections-header-label": {
                flex: "2",
                color: "#333",
                margin: "14px 0",
            },
            "& .content-count": {
                color: "#777",
                marginLeft: "4px",
            },
            "& .content-lists": {
                paddingTop: "10px"
            },
            "& .subsection-header-content": {
                display: "flex",
                flexDirection: "row",
                borderBottom: "2px solid #e2e2e2",
                "&:last-child": {
                    borderBottom: "none",
                }
            },
            "& .content-header-label": {
                color: "#333",
                margin: "14px 0",
                display: "flex"
            },
        },
        "& .content-options": {
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 20px 6px",
            marginBottom: "10px",
            flexDirection: "column",
            "& .option-button__label": {
                marginRight: "10px",
                marginLeft: "4px",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden"
            },
            "& .map-filter-option": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
                margin: "20px -20px 0",
                borderTop: "solid 1px #e2e2e2",
                borderBottom: "solid 1px #e2e2e2",
                "& .option-button__label": {
                    alignSelf: "center",
                    marginLeft: "0",
                }
            },
            "&.new-buttons": {
                marginBottom: "0",
                "& .map-filter-option": {
                    margin: "0 -20px 10px",
                }
            },
            "& .option-container": {
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
            },
            "& .left-buttons": {
                "& button": {
                    background: "#e2e2e2",
                }
            },
            "& .right-buttons": {
                "& button": {
                    border: "solid 2px #f5f5f5",
                    boxShadow: "none",
                    padding: "0 10px",
                    width: "auto",
                    height: "32px",
                    minWidth: "auto",
                    borderRadius: "10px",
                    fontSize: ".75rem",
                    marginLeft: "8px",
                    "& svg": {
                        width: "12px",
                        height: "12px"
                    }
                }
            },
            "& .toggle": {
                cursor: "pointer",
                width: "45px",
                height: "24px",
                "& .MuiFormControlLabel-root": {
                    marginLeft: "0px"
                },
                "& .MuiSwitch-root": {
                    paddingTop: "4px",
                    paddingBottom: "4px"
                },
                "& .MuiSwitch-switchBase": {
                    paddingTop: "2px",
                    paddingBottom: "2px"                
                }
            }
        },
        "& .selection-labels": {
            display: "flex",
            justifyContent: "space-between",
            padding: "0 20px",
            color: "#222",
            "&.selection-labels-right": {
                justifyContent: "flex-end",
            },
            "& .selection-count-label": {
                paddingTop: "6px",
            }
        },
        "& .body1": {
            fontFamily: "Roboto",
            fontSize: "0.875rem",
            lineHeight: "1.4285714286",
            fontWeight: "400"
        },
        "& .title4": {
            fontSize: "1.125rem",
            lineHeight: "1.3333333333",
        },
        "& .title5": {
            fontSize: "1rem",
            lineHeight: "1.25",
            margin: "0 0 .75em",
        },
        "& .subtitle3": {
            letterSpacing: "-0.75px",
            fontSize: "0.875rem",
            lineHeight: "1.4285714286",
        },
        "& .subtitle4": {
            letterSpacing: "-0.75px",
            fontSize: "0.6875rem",
            lineHeight: "1.4545454545",
        },
        "& .metadata3": {
            fontSize: "0.6875rem",
            lineHeight: "1.4545454545",
            fontWeight: "400",
        },
        "& #my-content": {
            height: "calc(100vh - 125px)",
            overflowY: "auto",
            marginBottom: "90px",
            "& .my-content-container": {
                margin: "0 20px",
            }
        },
        "& .markup-items": {
            borderBottom: "1px solid #e2e2e2",
            "&:last-child": {
                borderBottom: "none"
            },
            "& .MuiAccordion-root": {
                borderRadius: "0px",
                boxShadow: "none"
            },
            "& .MuiAccordionDetails-root": {
                padding: "0px",
                display: "block"
            }
        },
        "& .content-type-header": {
            margin: "5px 0",
            height: "50px",
            paddingTop: "10px",
        },
        "& .markup-header": {
            width: "100%",
            marginTop: "4px",
            "& .MuiAccordionSummary-root": {
                padding: "0px",
                minHeight: "auto",
            },
            "& .MuiAccordionSummary-content": {
                margin: "0px"
            },
            "& .MuiIconButton-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
                marginTop: "-12px"
            },
            "& .markup-count": {
                color: "#a5a5a5",
                marginLeft: "4px"
            }
        },
        "& .footer-actions": {
            position: "absolute",
            display: "flex",
            gap: "10px",
            bottom: "0",
            padding: "20px",
            width: "100%",
            background: whiteColor,
            "& .import-button": {
                flex: "4",
                "& button": {
                    background: "#e2e2e2"
                }
            }
        },
        "& .markup-item": {
            display: "flex",
            alignItems: "center",
            height: "55px",
            marginBottom: "4px",
            "& .markup-fields": {
                cursor: "pointer",
                width: "100%",
            }
        },
        "& .markup-list-item-fields": {
            display: "flex",
            alignItems: "center",
            "& .markup-icon-container": {
                marginRight: "10px",
                width: "32px",
                height: "32px",
                fontSize: "1rem",
                textAlign: "center",
                borderRadius: "50%",
            },
            "& .markup-label": {
                width: "200px",
                paddingRight: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            },
            "& .markup-header-label": {
                color: "#222",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }
        },
        "& .markup-icon": {
            display: "inline-block",
            textAlign: "center",
            borderRadius: "50%",
            "& svg": {
                fill: whiteColor,
            }
        },
        "& .icon-size--small": {
            width: "32px",
            height: "32px",
            border: "2px solid",
            "& svg": {
                marginTop: "3px",
                width: "16px",
                height: "16px",
            }
        },
        "& .color-orange": {
            background: "#f30",
            borderColor: "#c92800",
        },
        "& .markup-list-item__menu": {
            "& .MuiIconButton-root": {
                background: "transparent"
            }
        },
        "& .add-photo-button": {
            "&  .MuiFormControl-root":{
                margin: "0px !important",
                padding: "0px",
                display: "block",
                "& .MuiInput-formControl":{
                    "&:before": {
                        display: "none"
                    }
                },
                "& input": {
                    display: "none"
                },
                "& .MuiButton-root": {
                    margin: "0px",
                    padding: "0px",
                    fontSize: "1rem",
                    lineHeight: "1.3333333333",
                    color: "#333",
                    fontWeight: "500",
                    textTransform: "capitalize"
                }
            }
        },
        "& .photo-area-section": {
            "& .photo-display": {
                marginTop: "10px",
                width: "100%",
            },
            "& .image-wrapper": {
                "& .sd_loader": {
                    textAlign: "center",
                    marginTop: "10px"
                },
                "& .image": {
                    height: "170px",
                    display: "block",
                    margin: "auto",
                    width: "100%",
                    objectFit: "cover",
                    "&:first-child": {
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                    },
                    "&:last-child": {
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                    },
                    "&+.image": {
                        marginTop: "10px"
                    }
                }
            }
        },
        "&.photo-popover-content": {
            background: "#f9f6f2",
            "& .header": {
                width: "100%",
                float: "left",
                backgroundColor: whiteColor,
                minHeight: "50px",
                "& .icon": {
                    float: "left",
                    padding: "13px",
                    "& svg": {
                        display: "block"
                    }
                },
                "& .MuiIconButton-root": {
                    padding: "7px",
                    float: "right",
                    background: "transparent"
                }
            },
            "& .popover-title": {
                float: "left",
                display: "block",
                marginTop: "14px",
                "& h4": {
                    fontWeight: "normal",
                    fontSize: "18px",
                    margin: "0px",
                    lineHeight: "normal",
                }
            },
            "& .edit-markup-content": {
                "& .photo-container": {
                    margin: "25px 0 50px",
                    "& .photo-upload-box": {
                        width: "100%",
                        position: "relative",
                        backgroundColor: whiteColor,
                        border: "1px dashed #bbb",
                        margin: "10px 0",
                        borderRadius: "8px",
                        padding: "15px",
                        textAlign: "center",
                        fontSize: ".85rem",
                    },
                    "& .MuiFormControl-root": {
                        "& .MuiButton-root": {
                            backgroundColor: dangerColor[0],
                            borderRadius: "8px",
                            padding: "2px 20px",
                            color: whiteColor,
                            fontSize: ".85rem",
                            marginTop: "10px",
                            fontWeight: "normal"
                        }
                    }
                },
                "& div.edit-actions": {
                    backgroundColor: "transparent"
                }
            }
        }
    }
});

export default toolsStyle;
